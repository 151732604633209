import React from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import TableRowsIcon from "@mui/icons-material/TableRows";
import { Tooltip } from "@mui/material";

const UseSwitchView = ({ switchHandler, initialValue = "chart" }) => {
	let isChart = initialValue === "chart";
	const switchChangeHandler = () => switchHandler(isChart ? "table" : "chart");
	let iconElement = isChart ? <TableRowsIcon /> : <ShowChartIcon />;
	return (
		<SwitchViewStyled>
			<Tooltip placement="left" title={isChart ? "Lista" : "Quadro"} arrow>
				<span onClick={switchChangeHandler}>{iconElement}</span>
			</Tooltip>
		</SwitchViewStyled>
	);
};

export default UseSwitchView;

UseSwitchView.propTypes = {
	switchHandler: PropTypes.func.isRequired,
	initialValue: PropTypes.oneOf(["chart", "table"]),
};

const SwitchViewStyled = Styled.div`
  ${(props) => {
		let theme = props.theme;
		return `
        margin-left:auto;
        height:100%;
        display:flex;
        align-items:center;
        border-left:1px solid rgba(${theme.black},0.0625);
        >span{
            height:100%;
            display:flex;
            align-items:center;
            padding:0 ${theme.gXL};
            cursor:pointer;
            :hover{
              background:rgba(${theme.black},0.0625);
            }
        }
    `;
	}}
`;
