import React from "react";
import PropTypes from "prop-types";

import UseButton from "./useButton";
import UseInput from "./useInput";
import UseSelect from "./useSelect";
import UseTextArea from "./useTextArea";
import UseUploadImage from "./useUploadImage";
import { updateInputChangeSingle, updateObject, typePropsValidator } from "./utility";

const CompanyPopup = ({ selected, type, success }) => {
	const [company, setCompany] = React.useState({
		name: {
			label: "Empresa",
			validation: {
				required: true,
				min: 4,
			},
			valid: false,
			touched: false,
			value: "",
			element: {
				name: "name",
				type: "text",
				placeholder: "Nome da empresa",
				autoComplete: "Off",
				onChange: (e) => inputChangeHandler(e, "name"),
			},
		},
		observations: {
			label: "Observações",
			validation: {
				required: true,
				min: 1,
				max: 30,
			},
			valid: false,
			touched: false,
			value: "",
			element: {
				name: "observations",
				type: "text",
				placeholder: "Observações",
				autoComplete: "Off",
				onChange: (e) => inputChangeHandler(e, "observations"),
			},
		},
		logo: {
			label: "Logótipo da empresa",
			validation: {
				required: true,
			},
			valid: false,
			value: "",
			url: null,
			element: {
				aspect: 2 / 1,
				upload: (logo) => inputChangeHandler(logo, "logo"),
			},
		},
		commission: {
			label: "Comissão",
			validation: {
				required: true,
			},
			valid: false,
			touched: false,
			value: "",
			element: {
				name: "commission",
				type: "number",
				placeholder: "Comissão",
				autoComplete: "Off",
				min: "0",
				onChange: (e) => inputChangeHandler(e, "commission"),
			},
		},
		currency: {
			label: "Currency",
			validation: {
				required: false,
			},
			valid: true,
			touched: false,
			value: "EUR",
			element: {
				name: "currency",
				onChange: (e) => inputChangeHandler(e, "currency"),
				options: [
					{ label: "EURO", value: "EUR" },
					{ label: "USD", value: "USD" },
				],
			},
		},
		submit: false,
	});

	// update if popup is for edit
	React.useEffect(() => {
		if (type === "edit" && selected)
			setCompany((prev) => {
				let obj = updateObject(prev, {
					name: updateObject(prev.name, {
						value: selected.name,
						valid: true,
					}),
					observations: updateObject(prev.observations, {
						value: selected.observations,
						valid: true,
					}),
					logo: updateObject(prev.logo, {
						url: selected.logo,
						valid: true,
					}),
					commission: updateObject(prev.commission, {
						value: selected.commission,
						valid: true,
					}),
					currency: updateObject(prev.currency, {
						value: selected.currency,
						valid: true,
					}),
				});

				delete obj.commission;
				delete obj.currency;

				return obj;
			});
	}, [selected, type]);

	const inputChangeHandler = (e, key) =>
		setCompany((prev) =>
			updateInputChangeSingle(prev, key === "logo" ? e : e.target.value, key)
		);

	const submitClickHandler = () => {
		let toSend = {
			name: company.name.value,
			observations: company.observations.value,
		};

		if (type === "add") {
			// add company handler
			if (company.logo.value) toSend.logo = company.logo.value;
			if (company.commission.value) toSend.commission = company.commission.value;
			if (company.currency.value)  toSend.currency = company.currency.value;

		} else if (type === "edit") {
			// edit company handler
			if (company.logo.value) toSend.logo = company.logo.value;

		} else {
			// delete company handler
			let id = selected.id;
		}
	};

	let buttonText = null;
	let buttonSkin = null;
	let bodyElement = (
		<div className="body">
			<UseInput config={company.name} />
			<UseTextArea config={company.observations} />
			{type !== "edit" && (
				<>
					<UseInput config={company.commission} />
					<UseSelect config={company.currency} />
				</>
			)}
			<UseUploadImage
				aspect={company.logo.element.aspect}
				upload={company.logo.element.upload}
				buttonText={company.logo.label}
			/>
		</div>
	);
	if (type === "edit") {
		buttonText = `Guardar`;
		buttonSkin = "secondary";
	} else if (type === "delete") {
		buttonText = `Apagar`;
		buttonSkin = "danger";
		bodyElement = (
			<div className="body">
				Tem a certeza que quer eliminar {selected.name}?
			</div>
		);
	} else {
		buttonText = `Adicionar`;
		buttonSkin = "primary";
	}
	if (!company.submit && type !== "delete") buttonSkin = "disable";

	return (
		<div>
			{bodyElement}
			<div className="footer">
				<UseButton
					clicked={submitClickHandler}
					value={buttonText}
					type="button"
					skin={buttonSkin}
					size="md"
					display="default"
					style={{ marginLeft: "auto" }}
				/>
			</div>
		</div>
	);
};

export default CompanyPopup;

CompanyPopup.propTypes = {
	selected: PropTypes.object,
	type: typePropsValidator.isRequired,
	success: PropTypes.func.isRequired,
};
