import React from "react";
import PropTypes from "prop-types";

import UseButton from "./useButton";
import UseInput from "./useInput";
import UseTextArea from "./useTextArea";
import UseUploadImage from "./useUploadImage";
import { updateInputChangeSingle, updateObject, typePropsValidator } from "./utility";

const ProductPopup = ({ selected, type, success }) => {
	const [product, seProduct] = React.useState({
		name: {
			label: "Dispositivo",
			validation: {
				required: true,
				min: 4,
			},
			valid: false,
			touched: false,
			value: "",
			element: {
				name: "name",
				type: "text",
				placeholder: "Nome da dispositivo",
				autoComplete: "Off",
				onChange: (e) => inputChangeHandler(e, "name"),
			},
		},
		observations: {
			label: "Observações",
			validation: {
				required: true,
				min: 1,
				max: 30,
			},
			valid: false,
			touched: false,
			value: "",
			element: {
				name: "observations",
				type: "text",
				placeholder: "Observações",
				autoComplete: "Off",
				onChange: (e) => inputChangeHandler(e, "observations"),
			},
		},
		photo: {
			label: "Imagem do produto",
			validation: {
				required: true,
			},
			valid: false,
			value: "",
			url: null,
			element: {
				aspect: 2 / 2,
				upload: (logo) => inputChangeHandler(logo, "photo"),
			},
		},
		submit: false,
	});

	// update if popup is for edit
	React.useEffect(() => {
		if (type === "edit" && selected)
			seProduct((prev) => {
				let obj = updateObject(prev, {
					name: updateObject(prev.name, {
						value: selected.name,
						valid: true,
					}),
					observations: updateObject(prev.observations, {
						value: selected.observations,
						valid: true,
					}),
					photo: updateObject(prev.photo, {
						url: selected.photo,
						valid: true,
					}),
				});

				return obj;
			});
	}, [selected, type]);

	const inputChangeHandler = (e, key) =>
		seProduct((prev) =>
			updateInputChangeSingle(prev, key === "photo" ? e : e.target.value, key)
		);

	const submitClickHandler = () => {
		let toSend = {
			name: product.name.value,
			observations: product.observations.value,
			photo: product.photo.value,
		};
		if (type === "add") {
			// add product handler
		} else if (type === "edit") {
			// edit product handler
		} else {
			// delete product handler
			let id = selected.id;
		}
	};

	let buttonText = null;
	let buttonSkin = null;
	let bodyElement = (
		<div className="body">
			<UseInput config={product.name} />
			<UseTextArea config={product.observations} />
			<UseUploadImage
				aspect={product.photo.element.aspect}
				upload={product.photo.element.upload}
				buttonText={product.photo.label}
			/>
		</div>
	);
	if (type === "edit") {
		buttonText = `Guardar`;
		buttonSkin = "secondary";
	} else if (type === "delete") {
		buttonText = `Apagar`;
		buttonSkin = "danger";
		bodyElement = (
			<div className="body">
				Tem a certeza que quer eliminar {selected.name}?
			</div>
		);
	} else {
		buttonText = `Adicionar`;
		buttonSkin = "primary";
	}
	if (!product.submit && type !== "delete") buttonSkin = "disable";

	return (
		<div>
			{bodyElement}
			<div className="footer">
				<UseButton
					clicked={submitClickHandler}
					value={buttonText}
					type="button"
					skin={buttonSkin}
					size="md"
					display="default"
					style={{ marginLeft: "auto" }}
				/>
			</div>
		</div>
	);
};

export default ProductPopup;

ProductPopup.propTypes = {
	selected: PropTypes.object,
	type: typePropsValidator.isRequired,
	success: PropTypes.func.isRequired,
};
