import React from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

import { getShortMonthName } from "./utility";

export const CustomTooltip = ({ active, payload, label, totalText }) => {
  if (active && payload) {
    return (
      <TooltipStyled>
        <b className="title">{`${label}`}</b>
        {payload.length > 0 &&
          payload.map((item, index) => (
            <span key={index} style={{ color: item.fill }}>
              {totalText}:<span>{item.value}</span>
            </span>
          ))}
      </TooltipStyled>
    );
  }
  return null;
};

const UseAreaChart = ({ width, height, data, totalText = "Total" }) => {
  return (
    <AreaChart
      width={width}
      height={height}
      data={data}
      margin={{
        top: 40,
        right: 40,
        left: -20,
        bottom: 10,
      }}
    >
      <defs>
        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="10%" stopColor="#1078F0" stopOpacity={0.6} />
          <stop offset="95%" stopColor="#1078F033" stopOpacity={0.7} />
        </linearGradient>
      </defs>
      <CartesianGrid strokeDasharray="1 2" vertical={false} />
      <XAxis dataKey="x" tickFormatter={getShortMonthName} />
      <YAxis />
      <Tooltip totalText={totalText} content={<CustomTooltip />} />
      <Area
        type="monotone"
        dataKey="y"
        stroke="#1078F0"
        pointHoverRadius={10}
        strokeWidth={2}
        fill="url(#colorUv)"
        activeDot={{ r: 7 }}
      />
    </AreaChart>
  );
};

export default UseAreaChart;

UseAreaChart.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  totalText: PropTypes.string,
};

export const TooltipStyled = Styled.div`
  ${(props) => {
    let current = props.theme;
    return `
        background: rgb(${current.white});
        padding: ${current.gMD};
        border: 1px solid rgba(${current.cThree},0.2);
        display: flex;
        flex-flow: column;
        .title {
            margin-bottom: ${current.gMD};
            ${current.fMD};
            display: flex;
            justify-content: space-between;
            font-wight: bold;
            ${current.secondaryFont};
        }
        > span {
            margin-bottom: ${current.gMD};
            display: flex;
            justify-content: space-between;

            > span {
                ${current.secondaryFont};
              margin-left: ${current.gSM};
            }
        }
    `;
  }}
`;
