import PropTypes from "prop-types";

import LogoURL from "../logo.png";

const Logo = ({ onClick }) => {
  return (
    <h1 onClick={onClick}>
      <img src={LogoURL} alt="QRKoin" />
      <div>
        <p>
          QR<span>Koin</span>
        </p>
        <span>Cashless Payments</span>
      </div>
    </h1>
  );
};

export default Logo;

Logo.propTypes = {
  onClick: PropTypes.func,
};
