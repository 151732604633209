import React from "react";
import Styled from "styled-components";
import PropTypes from "prop-types";

import useBlanket from "./useBlanket";
import usePositions from "./usePositions";
import { removeFromDomByClassName } from "./utility";

const UseAutocomplete = ({
	multiple = false,
	options,
	selected,
	onChange,
	label,
	searchable = false,
	searchPlaceholder = "Pesquisar",
	emptyText = "Lista está vazia.",
	max = 5,
	className = "",
}) => {
	const containerRef = React.useRef();
	const autocompleteRef = React.useRef();

	const [isOpen, setIsOpen] = React.useState(false);
	const [search, setSearch] = React.useState("");
	const [highlightedIndex, setHighlightedIndex] = React.useState(0);
	const [inputSize, setInputSize] = React.useState({
		height: 51,
		width: 150,
	});

	React.useEffect(() => {
		let input = autocompleteRef.current;
		setInputSize({
			height: input.offsetHeight,
			width: input.offsetWidth,
		});
	}, [selected]);

	const selectHandler = React.useCallback(
		(option) => {
			if (multiple) {
				if (selected.find((o) => o.id === option.id)) {
					onChange(selected.filter((o) => o.id !== option.id));
				} else {
					if (selected.length <= max - 1) onChange([...selected, option]);
				}
			} else {
				if (option !== selected) onChange(option);
			}
		},
		[max, multiple, onChange, selected]
	);

	const isOptionSelected = React.useCallback(
		(option) => {
			return multiple
				? selected.find((o) => o.id === option.id)
				: JSON.stringify(option) === JSON.stringify(selected);
		},
		[multiple, selected]
	);

	React.useEffect(() => {
		if (isOpen) setHighlightedIndex(0);
	}, [isOpen]);

	const closeHandler = React.useCallback(() => {
		setIsOpen(false);
		setSearch("");
	}, []);

	// autocomplete controller
	const controller = React.useCallback(
		(e, current, search = false) => {
			switch (e.code) {
				case "Enter": {
					if (search) {
						selectHandler(current[highlightedIndex]);
						closeHandler();
					} else {
						setIsOpen((prev) => !prev);
						if (isOpen) selectHandler(current[highlightedIndex]);
					}
					break;
				}
				case "Space": {
					if (!search) {
						setIsOpen((prev) => !prev);
						if (isOpen) selectHandler(current[highlightedIndex]);
					}
					break;
				}
				case "ArrowUp":
				case "ArrowDown": {
					if (!isOpen && !search) {
						setIsOpen(true);
						break;
					}
					let toAdd = e.code === "ArrowDown" ? 1 : -1;
					let newValue = highlightedIndex + toAdd;
					if (newValue < 0 && newValue < current.length - 1) newValue = 0;
					setHighlightedIndex(newValue);
					break;
				}
				case "Escape":
					closeHandler();
					break;
				default:
					break;
			}
		},
		[closeHandler, isOpen, highlightedIndex, selectHandler]
	);

	React.useEffect(() => {
		const handler = (e) => {
			if (e.target !== autocompleteRef.current || searchable) return;
			controller(e, options);
		};
		autocompleteRef.current.addEventListener("keydown", handler);
		return () => {
			if (autocompleteRef.current)
				autocompleteRef.current.removeEventListener("keydown", handler);
		};
	}, [
		isOpen,
		highlightedIndex,
		options,
		searchable,
		selectHandler,
		controller,
	]);

	const openDropdown = () => setIsOpen((prev) => !prev);
	let isSelected = (multiple && selected.length > 0) || (!multiple && selected);

	const position = usePositions(containerRef);

	React.useEffect(() => {
		let addedHeight = 35;
		if (searchable) addedHeight = inputSize.height - 25;

		// creating UL
		const generateDropdown = () => {
			let root = document.getElementById("root");

			let optionElement = options;
			if (search !== "") {
				optionElement = optionElement.filter((item) => {
					let input = search.toUpperCase();
					let label = item.name ? item.name.toUpperCase() : "";
					let items = label.includes(input);
					return items;
				});
			}

			// creating ui
			let searchList = document.createElement("ul");
			searchList.setAttribute("id", "generatedDropdown");
			searchList.setAttribute(
				"style",
				`top:${position.top + addedHeight}px;left:${
					position.left - 12
				}px;width:${inputSize.width}px;`
			);
			searchList.classList.add("customAutocomplete");
			searchList.classList.add("toRemoveAutocomplete");
			searchList.classList.add("options");

			// creating li if search mode on
			if (searchable) {
				let searchHolder = document.createElement("li");
				searchHolder.classList.add("search");
				// creating input
				let searchInput = document.createElement("input");

				searchInput.value = search;
				searchInput.setAttribute("id", "inputField");
				searchInput.setAttribute("autocomplete", "off");
				searchInput.addEventListener("click", (e) => e.stopPropagation());
				searchInput.addEventListener("input", (e) => {
					e.stopPropagation();
					setSearch(e.target.value);
				});
				searchInput.addEventListener("keydown", (e) => {
					e.stopPropagation();
					controller(e, optionElement, true);
				});
				searchInput.placeholder = searchPlaceholder;

				searchHolder.append(searchInput);
				searchList.append(searchHolder);
			}

			// if list is empty
			if (optionElement.length === 0) {
				let emptyOptionList = document.createElement("li");
				emptyOptionList.classList.add("option");
				emptyOptionList.append(emptyText);

				searchList.append(emptyOptionList);
			}

			if (optionElement && optionElement.length > 0) {
				let wrapperDiv = document.createElement("div");
				for (let i = 0; i < optionElement.length; i++) {
					let optionList = document.createElement("li");

					let curr = optionElement[i];
					optionList.setAttribute("key", `${curr.id}`);
					optionList.classList.add("option");
					if (isOptionSelected(curr)) optionList.classList.add("selected");
					if (i === highlightedIndex) optionList.classList.add("highlighted");
					optionList.addEventListener("click", (e) => {
						e.stopPropagation();
						selectHandler(curr);
						closeHandler();
					});
					optionList.append(curr.name);
					wrapperDiv.append(optionList);
				}

				searchList.append(wrapperDiv);
			}

			root.append(searchList);
			if (searchable) {
				let inputField = document.getElementById("inputField");
				inputField.focus();
			}
		};

		removeFromDomByClassName("toRemoveAutocomplete");
		if (isOpen) generateDropdown();
	}, [
		isOpen,
		options,
		highlightedIndex,
		position,
		closeHandler,
		emptyText,
		inputSize,
		isOptionSelected,
		searchPlaceholder,
		search,
		searchable,
		selectHandler,
		controller,
	]);

	useBlanket(isOpen, closeHandler, 19, "autocomplete", 0);

	return (
		<AutocompleteStyled
			ref={autocompleteRef}
			tabIndex={0}
			onClick={openDropdown}
			isOpen={isOpen}
			className={className}
		>
			<div
				ref={containerRef}
				style={{ position: "fixed", visibility: "hidden", zIndex: 0 }}
			>
				&nbsp;
			</div>
			<div className={`label ${isSelected ? "isSelected" : ""}`}>
				{label}
				<span>&nbsp;</span>
			</div>
			<span className="value">
				{multiple
					? selected.map((item) => (
							<button
								key={`badge-${item.id}`}
								onClick={(e) => {
									e.stopPropagation();
									selectHandler(item);
								}}
								className="option-badge"
							>
								{item.name}
								<span className="remove-btn">&times;</span>
							</button>
					  ))
					: selected?.name}
			</span>
			{isSelected && (
				<button
					className="clear-btn"
					onClick={(e) => {
						e.stopPropagation();
						onChange(multiple ? [] : undefined);
					}}
				>
					&times;
				</button>
			)}

			<div className="divider"></div>
			<div className="caret"></div>
		</AutocompleteStyled>
	);
};

export default UseAutocomplete;

UseAutocomplete.propTypes = {
	multiple: PropTypes.bool,
	options: PropTypes.array,
	selected: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	onChange: PropTypes.func,
	label: PropTypes.string,
	searchable: PropTypes.bool,
	searchPlaceholder: PropTypes.string,
	emptyText: PropTypes.string,
	max: PropTypes.number,
	className: PropTypes.string,
};

const AutocompleteStyled = Styled.div`
    ${(props) => {
			return `
        margin: 10px;
        position: relative;
        min-height:1.79em;
        border:1px solid rgba(0,0,0,0.0625);
        display:flex;
        align-items:center;
        gap:5px;
        padding:10px;
        border-radius:4px;
        outline:none;
		z-index:20;

        :focus{
            border-color:rgba(25, 133, 248, 1);
        }

        .label{
          top:14px;
          position: absolute;
          //top:${props.isSelected ? "-11" : "14"}px;
          left:10px;
          display: flex;
          z-index: 1; 
          -webkit-transition: all 0.15s linear;
          -moz-transition: all 0.15s linear;
          -o-transition: all 0.15s linear;
          transition: all 0.15s linear;
          font-size: 12px;
          font-weight: normal;

          >span{
            background:rgb(255,255,255);
            height:1px;
            width: 100%;
            left: 0;
            position: absolute;
            top: calc(50% - 0px);
            z-index:-1;
          }
        }
        .label.isSelected{
          top:-11px;
        }
        .value{
            flex-grow: 1;
            display:flex;
            gap:.5em;
            flex-wrap:wrap;
            z-index:${props.isOpen ? "2" : "0"};
            font-weight: normal;

            .option-badge{
                display:flex;
                align-items:center;
                border: 1px solid rgba(0,0,0,0.0625);
                border-radius:15px;
                padding:2px 10px;
                gap:.25em;
                cursor:pointer;
                background:none;
                outline:none;
                font-weight:normal;
                font-size:12px;
                justify-content:center;

                :focus, :hover{
                    background-color:rgba(25, 133, 248, 0.8);
                    border: 1px solid rgba(25, 133, 248, 1);
                    color:rgba(255,255,255,1);

                    .remove-btn{
                      color:rgba(255,255,255,1);
                    }
                }

                .remove-btn{
                    padding: 2px 0 0 0;
                    color:rgba(248, 25, 25, 1);
                }
            }
        }

        .clear-btn{
            background:none;
            color:rgba(248, 25, 25, 1);
            border:none;
            outline:none;
            cursor:pointer;
            padding:0;
            font-size:1.2em;
            z-index:${props.isOpen ? "2" : "0"};

            :focus, :hover{
                color:rgba(0,0,0,0.2);
            }
        }

        .divider{
            background-color:rgba(0,0,0,0.0625);
            align-self:stretch;
            width:1px;
        }

        .caret{
            translate: 0 25%;
            border:.25em solid transparent;
            border-top-color:rgba(25, 133, 248, 0.8);
            z-index:${props.isOpen ? "2" : "0"};
            cursor:pointer;
        }

        .options{
            position:absolute;
            margin:0;
            padding:0;
            list-style:none;
            display:none;
            max-height:15em;
            overflow-y:auto;
            border: 1px solid rgba(0,0,0,0.0625);
            border-radius:4px;
            width:100%;
            left:0;
            top:100%;
            background:rgb(255,255,255);
            z-index:9999!important;
            font-weight: normal;

            .search{
              padding:0;
              border-bottom: 1px solid rgba(0,0,0,0.0625);
              margin-bottom:5px;

              >input{
                width:100%;
                height:30px;
                padding:5px;
                background:none;
                border:none;
                outline:none;
              }
            }

            .option{
                padding: 5px 10px;
                cursor:pointer;
            }

            .option.highlighted{
                color:rgba(255,255,255,1);
                background-color:rgba(25, 133, 248, 1);
            }
            .option.selected{
                background-color:rgba(25, 133, 248, 0.8);
            }

        }
        .options.show{
            display:block;
        }
      `;
		}}
`;
