import React from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";

import { AuthContext } from "./authContextProvider";

const PrivateRoute = () => {
  const { user } = React.useContext(AuthContext);
  const { pathname } = useLocation();
  if (!user) return <Navigate to="/" state={{ from: pathname }} />;
  return <Outlet />;
};

export default PrivateRoute;
