import moment from "moment-timezone";
import PropTypes from 'prop-types';

const typePropsValidator = PropTypes.oneOf(['add', 'edit', 'delete']);

typePropsValidator.isRequired = function(props, propName, componentName) {
	if (props[propName] === undefined) return new Error(`The prop '${propName}' is marked as required in '${componentName}', but its value is undefined.`);
	return typePropsValidator(props, propName, componentName);
  };
  
export { typePropsValidator };

export const updateObject = (obj, newObject) => ({
	...obj,
	...newObject,
});

export const selectNextOnEnter = (event, next) => {
	if (event.keyCode === 13 || event.keyCode === 9) {
		event.preventDefault();
		next.current.focus();
	}
};

export const checkValidity = (value, element, key) => {
	if (!element) {
		return true;
	}
	element[key].value = value;
	element[key].touched = true;

	let currentElement = element[key];
	let isValid = true;

	if (currentElement.validation.required) {
		isValid = value !== "" && isValid;
		if (currentElement.element.type === "checkbox") {
			isValid = value && isValid;
		} else if (currentElement.element.type !== "number") {
			if (currentElement.validation.type === "obj") {
				isValid = true && isValid;
			} else {
				isValid = value.trim() !== "" && isValid;
			}
		}
	}

	if (currentElement.validation.min) {
		isValid = value.length >= currentElement.validation.min && isValid;
	}

	if (currentElement.validation.max) {
		isValid = value.length <= currentElement.validation.max && isValid;
	}

	if (currentElement.validation.minVal) {
		if (currentElement.element.type === "number") {
			value = value.replaceAll(",", ".");
		}
		isValid = value >= currentElement.validation.minVal && isValid;
	}

	if (currentElement.validation.maxVal) {
		if (currentElement.element.type === "number") {
			value = value.replaceAll(",", ".");
		}
		isValid = value <= currentElement.validation.maxVal && isValid;
	}

	if (currentElement.element.type === "email") {
		const pattern =
			/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
		isValid = pattern.test(value) && isValid;
	}

	if (currentElement.validation.notEqual) {
		let toCompare = element[currentElement.validation.notEqual];
		if (toCompare.touched) {
			let otherVal = element[currentElement.validation.notEqual].value;
			isValid = value !== otherVal && isValid;
		}
	}

	if (currentElement.validation.equal) {
		let toCompare = element[currentElement.validation.equal];
		if (toCompare.touched) {
			let otherVal = element[currentElement.validation.equal].value;
			isValid = value === otherVal && isValid;
		}
	}

	return isValid;
};

export const updateInputChange = (data, value, key) => {
	let updatedObjects = updateObject(data, {
		[key]: updateObject(data[key], {
			value: value,
			valid: checkValidity(value, data, key),
			touched: true,
		}),
	});

	let isValidForm = true;
	for (let inputIdentifier in updatedObjects) {
		if (inputIdentifier !== "submit") {
			isValidForm = updatedObjects[inputIdentifier].valid && isValidForm;
		}
		if (updatedObjects[inputIdentifier].touched) {
			updatedObjects[inputIdentifier].valid = checkValidity(
				updatedObjects[inputIdentifier].value,
				updatedObjects,
				inputIdentifier
			);
		}
	}

	updatedObjects = updateObject(updatedObjects, {
		submit: updateObject(data.submit, {
			valid: isValidForm,
			element: updateObject(data.submit.element, {
				skin: isValidForm ? "primary" : "disable",
			}),
		}),
	});

	return updatedObjects;
};

export const checkForFormValidity = (data) => {
	let isValidForm = true;
	for (let inputIdentifier in data) {
		if (inputIdentifier !== "submit") {
			isValidForm = data[inputIdentifier].valid && isValidForm;
		}
	}
	return isValidForm;
};

export const updateInputChangeSingle = (data, value, key) => {
	let updatedObjects = updateObject(data, {
		[key]: updateObject(data[key], {
			value: value,
			valid: checkValidity(value, data, key),
			touched: true,
		}),
	});
	updatedObjects = updateObject(updatedObjects, {
		submit: checkForFormValidity(updatedObjects),
	});

	return updatedObjects;
};

export const dateFormat = (date) =>
	moment.tz(date, "Europe/Lisbon").format("YYYY-MM-DD");

export const dateTimeFormat = (date) =>
	moment.tz(date, "Europe/Lisbon").format("YYYY-MM-DD HH:mm:ss");

export const getFirstDayOfYear = () => {
	let firstDayOfTheYear = new Date(new Date().getFullYear(), 0, 1);
	return dateFormat(firstDayOfTheYear);
};

export const getFirstDayOfMonth = () => {
	let date = new Date();
	let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
	return dateFormat(firstDay);
};

export const getErrorMessageWidthStatus = (status) => {
	if(typeof status === 'string') return status
	switch(status) {
		case 200:
			return '200 OK: Solicitação concluída com êxito'
		case 201:
			return '201 Criado: A solicitação foi atendida e um novo recurso foi criado como resultado.'
		case 304:
			return '304 Não modificado: O recurso não foi modificado desde a última solicitação.'
		case 400:
			return '400 Solicitação incorreta: A solicitação não pôde ser entendida ou estava faltando os parâmetros necessários.'
		case 401:
			return '401 Não autorizado: A solicitação requer autenticação do usuário.'
		case 403:
			return '403 Proibido: A solicitação era válida, mas o servidor está se recusando a responder a ela.O usuário pode não ter as permissões necessárias para um recurso ou pode precisar de uma conta de algum tipo.'
		case 404:
			return '404 Não encontrado: O recurso solicitado não pôde ser encontrado.'
		case 408:
			return '408 Tempo limite da solicitação: A solicitação expirou, tente novamente mais tarde'
		case 409:
			return '409 Conflito: A solicitação não pôde ser concluída devido a um conflito com o estado atual do recurso.'
		case 410:
			return '410 Desaparecido: O recurso solicitado não está mais disponível e não estará disponível novamente.'
		case 422:
			return '422 Entidade não processável: A solicitação era válida, mas o servidor não pôde processá-la devido a erros semânticos no corpo da solicitação.'
		case 500:
			return '500 Erro interno do servidor: O servidor encontrou uma condição inesperada que o impediu de atender à solicitação.'
		case 502:
			return 'Nosso servidor encontrou um erro ao tentar se conectar ao servidor upstream.Tente novamente mais tarde ou entre em contato com nossa equipe de suporte se o problema persistir.'
		case 503:
			return 'Serviço 503 Indisponível: No momento, o servidor não consegue lidar com a solicitação devido a uma sobrecarga temporária do servidor.'
		default:
			return 'Algo deu errado: o servidor encontrou um erro interno.'
		}
};

export const calculateColumn = (
	height,
	columnHeight,
	headerHeight,
	paginationHeight
) => {
	let tHeight = 0;
	let limit = 10;

	if (height) {
		tHeight = height - paginationHeight;
		let spaceForColumn = tHeight - headerHeight;

		if (spaceForColumn > columnHeight)
			limit = Math.floor(spaceForColumn / columnHeight);
	}
	return { tHeight, limit };
};

export const valueFromNestedString = (obj, id) => {
	let arr = id.split(".");
	let arrLength = arr.length;
	for (let i = 0; i < arrLength; ++i) {
		let key = arr[i];
		if (key in obj) {
			obj = obj[key];
		} else {
			return;
		}
	}
	return obj;
};

export const getShortMonthName = (str) => {
	if (typeof str === "number" || (str.length <= 4 && str !== "Maio"))
		return str;
	return str.substring(0, 3);
};

export const formatAmount = (amount) => {
	if (amount === 0 || !amount || amount === "-") return "-";
	return new Intl.NumberFormat("pt-PT", {
		style: "currency",
		currency: "EUR",
	}).format(Number(Math.abs(amount) / 100).toFixed(2));
};

export const removeFromDomByClassName = (className) => {
	let toRemove = document.querySelectorAll(`.${className}`);
	toRemove.forEach((div) => div.remove());
};

export const dynamicSort = (property)=> {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}
