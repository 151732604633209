import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@mui/material'

const UseTooltip = ({ children, ...rest }) => (
	<Tooltip placement="top" {...rest} arrow>
		{children}
	</Tooltip>
)
UseTooltip.propTypes = {
	children: PropTypes.node,
}
export default UseTooltip