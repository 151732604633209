import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";

import { Current } from "../theme";

const UseLoading = ({ width = "100%", height = "100%" }) => {
	return (
		<div
			style={{
				width: width,
				height: height,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<CircularProgress sx={{ color: Current.primaryHASH }} />
		</div>
	);
};

export default UseLoading;

UseLoading.propTypes = {
	width: PropTypes.string,
	height: PropTypes.string,
};
