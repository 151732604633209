import React from "react";
import Styled from "styled-components";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { Grid } from "@mui/material";

import ChangePassword from "../components/changePassword";
import PageLayout from "../components/pageLayout";
import { AuthContext } from "../components/authContextProvider";
import { WrapperStyled } from "../theme";

const Profile = () => {
  const { user } = React.useContext(AuthContext);
  return (
    <WrapperStyled width="480">
      <h2>
        <PermIdentityIcon />
        Informações do usuário
      </h2>
      <ProfileStyled>
        <Grid container spacing={2} alignItems="center">
          <Grid item sm={3} xs={12}>
            Email:
          </Grid>
          <Grid item sm={9} xs={12}>
            {user.email}
          </Grid>
        </Grid>
      </ProfileStyled>
      <h2>
        <ChangeCircleIcon />
        Alterar senha
      </h2>
      <ProfileStyled noMargin>
        <ChangePassword />
      </ProfileStyled>
    </WrapperStyled>
  );
};

export default PageLayout(Profile, "profile");

const ProfileStyled = Styled.div`
    ${(props) => {
      let theme = props.theme;
      return `
            padding:${theme.gMD};;
            margin-bottom:${theme.gLG};
        `;
    }}
`;
