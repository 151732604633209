import React from "react";

import UseDialog from "./useDialog";
import { IframeStyled } from "../theme";

const Footer = () => {
  const dialogRef = React.useRef(null);
  let currentYear = new Date().getFullYear();
  return (
    <>
      <div className="footer">
        © {currentYear} QRKoin.
        <span onClick={() => dialogRef.current.Open()}>
          Política de Privacidade e Cookies
        </span>
      </div>
      <UseDialog ref={dialogRef} title="Política de Privacidade e Cookies">
        <div className="body">
          <IframeStyled src="https://storage.googleapis.com/qrkoin_privacy_files/policy.html" />
        </div>
      </UseDialog>
    </>
  );
};
export default React.memo(Footer);
