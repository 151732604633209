import React from "react";
import Styled from "styled-components";
import PropTypes from "prop-types";

const UseTextArea = ({ config }) => {
  return (
    <TextAreaStyled
      className={`${config.className} input ${
        !config.valid && config.touched ? "error" : ""
      }`}
    >
      <textarea
        {...config.element}
        defaultValue={config.value}
        className={`${config.value ? "focused" : ""}`}
        rows={config.element.rows ? config.element.rows : 6}
      />
      <label htmlFor={config.element.name} className="placeholder">
        {config.label}
      </label>
    </TextAreaStyled>
  );
};

export default React.memo(UseTextArea);

UseTextArea.propTypes = {
  config: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    element: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    className: PropTypes.string,
  }),
};

const TextAreaStyled = Styled.div`
    ${(props) => {
      let theme = props.theme;
      return `
            display:block;
            position: relative;
            margin-bottom:5px;

            >textarea{
                width:calc(100% - 22px);
                border: none;
                -webkit-appearance: none;
                -ms-accelerator: none;
                -moz-appearance: none;
                appearance: none;
                resize: none;
                outline: none;
                border-radius:4px;
                padding:${theme.gMD};
                min-height:50px;
                display:flex;
                justify-content:center;
                align-items:center;

                border:1px solid rgba(${theme.black},0.23);
            }
            
            .placeholder {
                pointer-events: none;
                position: absolute;
                top: calc(35px - 5px);
                left: 11px;

                -webkit-transform: translateY(-100%);
                transform: translateY(-100%);

                transition: top .2s ease,font-size .2s ease,color .2s ease;

                height:20px;
                background:rgb(${theme.white});
                width:calc(100% - 42px);
                color:rgba(${theme.black},0.4);

                ::after {
                    height:1px;
                    background:rgb(${theme.white});
                    content: "";
                    left: -5px;
                    position: absolute;
                    top: calc(50%);
                    width: 100%;
                    z-index: -1;
                    border:0;
                    padding:0 5px;
                }

            }
            .focused+.placeholder, textarea.focused+.placeholder, textarea:focus+.placeholder {
                top: 10px!important;
                width:auto;
                background:transparent;
                font-size:14px;
                color:rgba(${theme.black},0.4);
            }


        `;
    }}
`;
