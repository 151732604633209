export const log = (message, error, type) => {
  if (process.env.REACT_APP_NODE_ENV === "development") {
    if (error) {
      if (type) {
        console.log(message);
        console.log(error);
      } else {
        console.error(message + " " + error);
      }
    } else {
      console.log(message);
    }
  }
};
