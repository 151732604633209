import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  sendPasswordResetEmail as firebaseSendPasswordResetEmail,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
  getIdToken,
} from "firebase/auth";

import { log } from "./logger";

const firebaseApp = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
};

const Firebase = initializeApp(firebaseApp);

const COMMON_ERRORS = {
	'auth/network-request-failed':'Ocorreu um erro de rede: como tempo limite, conexão interrompida ou host inacessível.',
	'default':'Algo deu errado: o servidor encontrou um erro interno.',
	'auth/invalid-email-password':'Email/senha inválidos.',
	'auth/invalid-email':'Endereço de email inválido.',
	'auth/user-token-expired':'O token do usuário \'s expirou. O usuário deve entrar novamente.',
	'auth/operation-not-allowed':'A verificação de email não está habilitada. Fale com o atendimento ao cliente.',
	'auth/argument-error':'O argumento forceRefresh é inválido.',
	'auth/weak-password':'A senha deve ter pelo menos 6 caracteres',
	'auth/invalid-credential':'Senha inválidos.',
}

export const signIn = (email, password) => {
  return new Promise((resolve, reject) => {
    signInWithEmailAndPassword(getAuth(), email, password)
      .then((userCredential) => {
        log("Success: signIn--firebase.js", userCredential.user, "log");
        resolve(userCredential.user);
      })
      .catch((error) => {
				log(`Error: signIn----${email}----firebase.js`, error)
				switch (error.code) {
					case 'auth/invalid-email':
					case 'auth/wrong-password':
					case 'auth/user-not-found':
					case 'auth/user-disabled':
						reject(COMMON_ERRORS['auth/invalid-email-password'])
						break
					default:
						reject(COMMON_ERRORS['default'])
					}
			})
  });
};

export const signOut = () => {
  return new Promise((resolve, reject) => {
    firebaseSignOut(getAuth())
      .then(() => {
        log(`Success: signOut--firebase.js`);
        resolve();
      })
			.catch((error) => {
				log('Error: signOut----firebase.js', error)
				switch (error.code) {
					case 'auth/network-request-failed':
						reject(COMMON_ERRORS['auth/network-request-failed'])
						break
					default:
						reject(COMMON_ERRORS['default'])
					}
			})
  });
};

export const sendPasswordResetEmail = (email) => {
  return new Promise((resolve, reject) => {
    firebaseSendPasswordResetEmail(getAuth(), email)
      .then(() => {
        log("Success: sendPasswordResetEmail--firebase.js");
        resolve(200);
      })
			.catch((error) => {
				log('Error: sendPasswordResetEmail----firebase.js', error)
				switch (error.code) {
					case 'auth/invalid-email':
					case 'auth/user-not-found':
					case 'auth/operation-not-allowed':
						reject(COMMON_ERRORS['auth/invalid-email'])
						break
					case 'auth/network-request-failed':
						reject(COMMON_ERRORS['auth/network-request-failed'])
						break
					default:
						reject(COMMON_ERRORS['default'])
					}
			})
  });
};

export const changePassword = (email, password, newPassword) => {
  return new Promise((resolve, reject) => {
    if (email && password && newPassword) {
      const credential = EmailAuthProvider.credential(email, password);
      const currentUser = getAuth().currentUser;
      reauthenticateWithCredential(currentUser, credential)
        .then(() => {
          updatePassword(currentUser, newPassword)
            .then(() => {
              log("Success: changePassword--firebase.js");
              resolve(200);
            })
            .catch(error=>{
              log('Error: changePassword----firebase.js', error)
              switch (error.code) {
                case 'auth/weak-password':
                  reject(COMMON_ERRORS['auth/weak-password'])
                  break
                case 'auth/requires-recent-login':
                case 'auth/user-token-expired':
                  reject(COMMON_ERRORS['auth/user-token-expired'])
                  break
                case 'auth/network-request-failed':
                  reject(COMMON_ERRORS['auth/network-request-failed'])
                  break
                default:
                  reject(COMMON_ERRORS['default'])
                }
            })
        })
        .catch((error) => {
          log('Error: reauthenticateWithCredential----firebase.js', error)
          switch (error.code) {
            case 'auth/invalid-credential':
            case 'auth/user-disabled':
              reject(COMMON_ERRORS['auth/invalid-credential'])
              break
            case 'auth/user-mismatch':
            case 'auth/user-token-expired':
              reject(COMMON_ERRORS['auth/user-token-expired'])
              break
            case 'auth/operation-not-allowed':
              reject('Esta operação não é permitida.')
              break
            case 'auth/network-request-failed':
              reject(COMMON_ERRORS['auth/network-request-failed'])
              break
            default:
              reject(COMMON_ERRORS['default'])
            }
        })
    } else {
			log('Error: changePassword----firebase.js, Missing data email/password/newPassword')
			reject(COMMON_ERRORS['auth/invalid-email-password'])
    }
  });
};

export const getFirebaseToken = () => {
  return new Promise((resolve, reject) => {
    getIdToken(getAuth().currentUser)
      .then((token) => resolve(token))
			.catch((error) => {
				log('Error: getFirebaseToken----firebase.js', error)
				switch (error.code) {
					case 'auth/argument-error':
						reject(COMMON_ERRORS['auth/argument-error'])
						break
					case 'auth/invalid-user-token':
						case 'auth/user-token-expired':
							reject(COMMON_ERRORS['auth/user-token-expired'])
						break
					case 'auth/network-request-failed':
						reject(COMMON_ERRORS['auth/network-request-failed'])
						break
					default:
						reject(COMMON_ERRORS['default'])
					}
			})
  });
};

export default Firebase;
