import React from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { Grid } from "@mui/material";

import PageLayout from "../components/pageLayout";
import UseLoading from "../components/useLoading";
import UseAutocomplete from "../components/useAutocomplete";
import UseDatePicker from "../components/useDatePicker";
import UsePopOver from "../components/usePopOver";
import UseSwitchView from "../components/useSwitchView";
import UseButton from "../components/useButton";
import UseLineChart from "../components/useLineChart";
import UseTable from "../components/useTable";
import UseExportToExcel from "../components/useExportToExcel";
import UseEmpty from "../components/useEmpty";
import { WrapperStyled } from "../theme";
import { useSizeWithChild } from "../components/useWindowSize";
import {
	dateFormat,
	dynamicSort,
	formatAmount,
	getErrorMessageWidthStatus,
	getFirstDayOfMonth,
	updateObject,
} from "../components/utility";
import { log } from "../logger";
import { get, getAll } from "../serverRequest";
import { AuthContext } from "../components/authContextProvider";
import UseTooltip from "../components/UseTooltip";


const commonItemToPush = (movement)=>{
	return {
		count: 1,
		totalAmount: movement.amount,
		stripeAmount: movement.mbway ? 0 : movement.amount,
		stripeCount: movement.mbway ? 0 : 1,
		mbwayAmount: movement.mbway ? movement.amount : 0,
		mbwayCount: movement.mbway ? 1 : 0,
		commission: movement.commission,
	}
}

const addToItem =(toReturn, index, movement)=>{
	toReturn[index].count += 1;
	toReturn[index].totalAmount += movement.amount;
	toReturn[index].stripeAmount += movement.mbway ? 0 : movement.amount;
	toReturn[index].stripeCount += movement.mbway ? 0 : 1;
	toReturn[index].mbwayAmount += movement.mbway ? movement.amount : 0;
	toReturn[index].mbwayCount += movement.mbway ? 1 : 0;
	toReturn[index].commission += movement.commission;
}

const getMovementByLocations =(list, companyId)=>{
	let toReturn = []
	for (const movement of list){
		if (movement.description !== "**Balance**" && movement.company && movement.company.id === companyId){
			let index = toReturn.findIndex((item) => item.id === movement.company.location.id);
			if(index === -1){
				toReturn.push({
					id: movement.company.location.id,
					name: movement.company.location.name,
					...commonItemToPush(movement)
				})
			} else {
				addToItem(toReturn, index, movement);
			}
		}
	}
	return toReturn
}

const getMovementByDevices = (list, locationId) => {
	let toReturn = [];
	for (const movement of list) {
		if (movement.description !== "**Balance**" && movement.company && movement.company.location.id === locationId){
			let index = toReturn.findIndex((item) => item.id === movement.company.location.device.id);
			if (index === -1) {
				toReturn.push({
					id: movement.company.location.device.id,
					name: movement.company.location.device.name,
					...commonItemToPush(movement)
				});
			} else {
				addToItem(toReturn, index, movement);
			}
		}
	}
	return toReturn;
};


const getTotalBalance =(list)=>{
	let total = {
		balance: {
			amount: 0,
			count: 0,
		},
		mbway: {
			amount: 0,
			count: 0,
		},
		stripe: {
			amount: 0,
			count: 0,
		},
		commission: {
			amount: 0,
			count: 0,
		},
		all: {
			amount: 0,
			count: 0,
		},
	};
	for (let movement of list){
		if (movement.description === "**Balance**" && movement.amount >= 1000) {
			// Total balance
			total.balance.amount += movement.amount;
			total.balance.count += 1;
		} else {
			if (movement.mbway) {
				// Total by MBWay
				total.mbway.amount += movement.amount;
				total.mbway.count += 1;
			} else {
				// Total by stripe
				total.stripe.amount += movement.amount;
				total.stripe.count += 1;
			}

			// Total All
			if (movement.amount) {
				total.all.amount += movement.amount;
				total.all.count += 1;
			}

			// Total commission
			if (movement.commission) {
				total.commission.amount += movement.commission;
				total.commission.count += 1;
			}
		}
	}
	return [total]
}

const getMovementListByCompany = (list)=>{
	let toReturn = [];
	for (const movement of list) {
		if (movement.description !== "**Balance**" && movement.company){
			let index = toReturn.findIndex((item) => item.id === movement.company.id);
			if(index === -1){
				toReturn.push({
					id: movement.company.id,
					name: movement.company.name,
					...commonItemToPush(movement)
				})
			} else {
				addToItem(toReturn, index, movement);
			}
			}
	}
	return toReturn
}

const arrangeDataForChart = (list) => {
	let toReturn = [];

	for (let i = 0; i < list.length; i++) {
		let company = list[i];
		let data = list[i].data;
		for (let j = 0; j < data.length; j++) {
			let index = toReturn.findIndex((item) => item.x === data[j].x);

			if (index === -1) {
				toReturn.push({
					x: data[j].x,
					[company.name]: data[j].y,
				});
			} else {
				toReturn[index][company.name] = data[j].y;
			}
		}
	}

	return toReturn;
};

const Movements = () => {
	const wrapDivRef = React.useRef();
	const [calculateHeight, setCalculateHeight] = React.useState(true);
	const { snackbar } = React.useContext(AuthContext);

	const [isOpen, setIsOpen] = React.useState(false);
	const filterOpenClickHandler = () => setIsOpen((prev) => !prev);

	const wrapDiv = useSizeWithChild(wrapDivRef, calculateHeight);
	let chartHeight, chartWidth;
	if (wrapDiv.height && wrapDiv.width) {
		chartHeight = wrapDiv.height;
		chartWidth = wrapDiv.width;
	}

	const [data, setData] = React.useState({
		total: null,
		movements: {
			chart: [],
			table: [],
			list: [],
			loading: true,
			reload: true,
			byLocation: {
				current: "",
				list: [],
				byDevice: {
					current: "",
					list: [],
				},
			},
		},
		companies: {
			list: [],
			selected: [],
			reload: true,
		},
		locations: {
			selected: null,
		},
		devices: {
			selected: null,
		},
		filter: {
			clicked: false,
			from: getFirstDayOfMonth(),
			to: dateFormat(new Date()),
		},
		view: "chart",
	});

	// Get companies list
	React.useEffect(() => {
		let isMounted = true;
		if (data.companies.reload) {
			get("companies/list")
				.then((response) => {
					log("Success: get-companies/list--movements.js", response, "log");
					if (isMounted) {
						setData((prev) =>
							updateObject(prev, {
								companies: updateObject(prev.companies, {
									list: response.sort(dynamicSort('name')),
									reload: false,
									loading: false,
								}),
							})
						);
					}
				})
				.catch((status) => {
					log("Error: get-companies/list--movements.js", status);
					setData((prev) =>
						updateObject(prev, {
							companies: updateObject(prev.companies, {
								reload: false,
								loading: false,
							}),
						})
					);
					snackbar(getErrorMessageWidthStatus(status));
				});
		}
		return () => (isMounted = false);
	}, [data.companies.reload, snackbar]);

	const changeHandler = (current, type, action) => {
		let selected = current;
		if (action === "multiple") selected = [...current];

		setData((prev) => {
			let toUpdate = {};
			if (type === "companies") {
				toUpdate = {
					companies: updateObject(prev.companies, {
						selected: selected,
					}),
					locations: updateObject(prev.locations, {
						selected: null,
					}),
					devices: updateObject(prev.devices, {
						selected: null,
					}),
				};
			} else if (type === "locations") {
				toUpdate = {
					locations: updateObject(prev.locations, {
						selected: selected,
					}),
					devices: updateObject(prev.devices, {
						selected: null,
					}),
				};
			} else {
				toUpdate = {
					devices: updateObject(prev.devices, {
						selected: selected,
					}),
				};
			}
			return updateObject(prev, toUpdate);
		});
	};

	const handleChangeDate = (value, key) => {
		setData((prev) =>
			updateObject(prev, {
				filter: updateObject(prev.filter, {
					[key]: dateFormat(value),
				}),
			})
		);
	};

	// get chart data for movements
	React.useEffect(() => {
		let isMounted = true;
		if (
			data.movements.reload &&
			data.movements.loading &&
			data.view === "chart"
		) {
			let params = {
				from: data.filter.from + " 00:00:00",
				to: data.filter.to + " 23:59:59",
			};
			if (data.companies.selected.length > 1) {
				getAll("movements/chart", params, data.companies.selected)
					.then((response) => {
						let formateData = arrangeDataForChart(response);
						if (isMounted) {
							setData((prev) =>
								updateObject(prev, {
									movements: updateObject(prev.movements, {
										chart: formateData,
										reload: false,
										loading: false,
									}),
									filter: updateObject(prev.filter, {
										clicked: false,
									}),
								})
							);
						}
					})
					.catch((status) => {
						log("Error: get-movements/chart--movements.js", status);
						setData((prev) =>
							updateObject(prev, {
								movements: updateObject(prev.movements, {
									reload: false,
									loading: false,
								}),
								filter: updateObject(prev.filter, {
									clicked: false,
								}),
							})
						);
					});
			} else {
				if (data.companies.selected.length > 0)
					params.companyId = data.companies.selected[0].id;
				if (data.locations.selected)
					params.locationId = data.locations.selected.id;
				if (data.devices.selected) params.deviceId = data.devices.selected.id;
				get("movements/chart", params)
					.then((response) => {
						if (isMounted) {
							setData((prev) =>
								updateObject(prev, {
									movements: updateObject(prev.movements, {
										chart: response,
										reload: false,
										loading: false,
									}),
									filter: updateObject(prev.filter, {
										clicked: false,
									}),
								})
							);
						}
					})
					.catch((status) => {
						log("Error: get-movements/chart--movements.js", status);
						setData((prev) =>
							updateObject(prev, {
								movements: updateObject(prev.movements, {
									reload: false,
									loading: false,
								}),
								filter: updateObject(prev.filter, {
									clicked: false,
								}),
							})
						);
						snackbar(getErrorMessageWidthStatus(status));
					});
			}
		}
		return () => (isMounted = false);
	}, [
		data.movements.reload,
		data.movements.loading,
		data.companies.selected,
		data.locations.selected,
		data.devices.selected,
		data.filter,
		data.view,
		snackbar,
	]);

	// get data for table form movements
	React.useEffect(() => {
		let isMounted = true;
		if (
			data.movements.reload &&
			data.movements.loading &&
			data.view === "table"
		) {
			let params = {
				from: data.filter.from + " 00:00:00",
				to: data.filter.to + " 23:59:59",
			};
			get("movements/list", params)
				.then((response) => {
					let table = getMovementListByCompany(response);
					let [totalAmount] = getTotalBalance(response)

					if (isMounted) {
						setData((prev) =>
							updateObject(prev, {
								total: totalAmount,
								movements: updateObject(prev.movements, {
									table,
									list: response,
									reload: false,
									loading: false,
								}),
								filter: updateObject(prev.filter, {
									clicked: false,
								}),
							})
						);
					}
				})
				.catch((status) => {
					log("Error: get-movements/list--movements.js", status);
					setData((prev) =>
						updateObject(prev, {
							movements: updateObject(prev.movements, {
								reload: false,
								loading: false,
							}),
							filter: updateObject(prev.filter, {
								clicked: false,
							}),
						})
					);
					snackbar(getErrorMessageWidthStatus(status));
				});
		}
		return () => (isMounted = false);
	}, [
		data.movements.reload,
		data.movements.loading,
		data.filter,
		data.view,
		snackbar,
	]);

	const switchViewHandler = React.useCallback((current) => {
		setCalculateHeight((prev) => !prev);
		setData((prev) =>
			updateObject(prev, {
				view: current,
				movements: updateObject(prev.movements, {
					reload: true,
					loading: true,
				}),
			})
		);
	}, []);

	const filterClickedHandler = () => {
		setData((prev) =>
			updateObject(prev, {
				movements: updateObject(prev.movements, {
					loading: true,
					reload: true,
				}),
				filter: updateObject(prev.filter, {
					clicked: true,
				}),
			})
		);
		setIsOpen(false)
	};

	let chartMemo = React.useMemo(() => {
		if (
			data.movements.chart.length > 0 &&
			chartWidth &&
			chartHeight &&
			data.view === "chart"
		) {
			return (
				<UseLineChart
					width={chartWidth}
					height={chartHeight}
					data={data.movements.chart}
					selected={data.companies.selected}
					totalText="Número total de movimentos"
				/>
			);
		} else {
			return;
		}
	}, [chartWidth, chartHeight, data.movements.chart, data.view, data.companies.selected]);

	const columns = React.useMemo(() => {
		return [
			{
				id: "name",
				label: "Nome",
				searchBy: false,
				orderBy: true,
				align: "left",
			},
			{
				id: "totalAmount",
				label: "Montante",
				searchBy: false,
				orderBy: true,
				align: "center",
				format: (value) => formatAmount(value),
				className: "leftBorder",
			},
			{
				id: "count",
				label: "Transacções",
				align: "center",
				searchBy: false,
				orderBy: true,
				format: (value) => (value ? value : "-"),
			},
			{
				id: "mbwayAmount",
				label: "Montante",
				searchBy: false,
				orderBy: true,
				align: "center",
				format: (value) => formatAmount(value),
				className: "leftBorder",
			},
			{
				id: "mbwayCount",
				label: "Transacções",
				searchBy: false,
				orderBy: true,
				align: "center",
				format: (value) => (value ? value : "-"),
				className: "rightBorder",
			},
			{
				id: "stripeAmount",
				label: "Montante",
				searchBy: false,
				orderBy: true,
				align: "center",
				format: (value) => formatAmount(value),
			},
			{
				id: "stripeCount",
				label: "Transacções",
				searchBy: false,
				orderBy: true,
				align: "center",
				format: (value) => (value ? value : "-"),
				className: "rightBorder",
			},
			{
				id: "commission",
				label: "Comissão",
				searchBy: false,
				orderBy: true,
				align: "center",
				format: (value) => formatAmount(value),
			},
		];
	}, []);

	const rowClickHandler = (row, by) => {
		if (by === "byLocation") {
			setData((prev) =>
				updateObject(prev, {
					movements: updateObject(prev.movements, {
						byLocation: updateObject(prev.movements.byLocation, {
							current:
								row.id === prev.movements.byLocation.current ? "" : row.id,
								list: getMovementByLocations(prev.movements.list, row.id),
						}),
					}),
				})
			);
		} else if (by === "byDevice") {
			setData((prev) =>
				updateObject(prev, {
					movements: updateObject(prev.movements, {
						byLocation: updateObject(prev.movements.byLocation, {
							byDevice: updateObject(prev.movements.byLocation.byDevice, {
								current:
									row.id === prev.movements.byLocation.byDevice.current
										? ""
										: row.id,
										list: getMovementByDevices(prev.movements.list, row.id),
							}),
						}),
					}),
				})
			);
		}
	};

	let tableMemo = React.useMemo(() => {
		if (chartWidth && chartHeight && data.view === "table") {
			if (data.movements.loading && data.movements.table.length === 0) {
				return (
					<UseLoading width={`${chartWidth}px`} height={`${chartHeight}px`} />
				);
			} else if (!data.movements.loading && data.movements.table.length === 0) {
				return (
					<UseEmpty
						width={`${chartWidth}px`}
						height={`${chartHeight}px`}
						text="Não encontramos nenhum movimentos com estas configurações de filtro."
					/>
				);
			} else {
				const newColumns = [
					{
						label: "",
						colSpan: 1,
						align: "left",
					},
					{
						label: "Total",
						colSpan: 2,
						align: "center",
						className: "leftBorder",
					},
					{
						label: "MBWay",
						colSpan: 2,
						align: "center",
						className: "leftBorder rightBorder",
					},
					{
						label: "QRKoin",
						colSpan: 2,
						align: "center",
						className: "rightBorder",
					},
					{
						label: "",
						colSpan: 1,
						align: "left",
					},
				];
				return (
					<UseTable
						tableWidth={chartWidth}
						tableHeight={chartHeight - 40}
						data={data.movements.table}
						columns={columns}
						addedColumn={newColumns}
						className="marginTop noStripe"
						columnsHeight={50.52}
						rowClick={rowClickHandler}
						firstLabelRows={data.movements.byLocation.current}
						firstLabelRowsData={data.movements.byLocation.list}
						secondLabelRows={data.movements.byLocation.byDevice.current}
						secondLabelRowsData={data.movements.byLocation.byDevice.list}
						defaultOrderBy="name"
					/>
				);
			}
		} else {
			return;
		}
	}, [
		chartWidth,
		chartHeight,
		data.movements.loading,
		data.movements.table,
		data.view,
		columns,
		data.movements.byLocation,
	]);

	const companyMemoElement = React.useMemo(
		() => (
			<UseAutocomplete
				label="Empresas"
				options={data.companies.list.sort(dynamicSort('name'))}
				selected={data.companies.selected}
				onChange={(data) => changeHandler(data, "companies", true)}
				multiple={true}
				searchable={true}
				className="noMargin"
			/>
		),
		[data.companies.selected, data.companies.list]
	);
	return (
		<WrapperStyled ref={wrapDivRef} isSelectedCompany={data.companies.selected.length === 0}>
			<h2>
				<TrendingUpIcon />
				Movimentos
				{data.companies.selected.length === 0 && (
					<UseSwitchView
						switchHandler={switchViewHandler}
						initialValue={data.view}
					/>
				)}
				<span>
					<UseTooltip title='Filtros' placement='top'>
						<span onClick={filterOpenClickHandler} className={isOpen ? "isOpen" : ""}>
							<FilterAltIcon />
						</span>
						</UseTooltip>
						<UsePopOver
						isOpen={isOpen}
						toggler={setIsOpen}
						top={25}
						>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<UseDatePicker
										name="from"
										label="Desde"
										value={data.filter.from}
										onChange={(value) => handleChangeDate(value, "from")}
									/>
								</Grid>
								<Grid item xs={12}>
									<UseDatePicker
										name="to"
										label="Até"
										value={data.filter.to}
										onChange={(value) => handleChangeDate(value, "to")}
									/>
								</Grid>

								{data.companies.list.length > 0 && data.view === "chart" && (
									<Grid item xs={12}>
										{companyMemoElement}
									</Grid>
								)}

								{data.companies.selected.length > 0 &&
									data.companies.selected.length <= 1 &&
									data.companies.selected[0].locations.length > 0 && (
										<Grid item xs={12}>
											<UseAutocomplete
												label="Localizações"
												options={data.companies.selected[0].locations.sort(dynamicSort('name'))}
												selected={data.locations.selected}
												onChange={(data) => changeHandler(data, "locations", false)}
												searchable={true}
												className="noMargin"
											/>
										</Grid>
									)}

								{data.locations.selected &&
									data.locations.selected.devices.length > 0 && (
										<Grid item xs={12}>
											<UseAutocomplete
												label="Dispositivos"
												options={data.locations.selected.devices.sort(dynamicSort('name'))}
												selected={data.devices.selected}
												onChange={(data) => changeHandler(data, "devices", false)}
												searchable={true}
												className="noMargin"
											/>
										</Grid>
									)}
									
								<Grid item xs={12}>
									<UseButton
										type="button"
										skin={data.filter.clicked ? "disable" : "primary"}
										clicked={filterClickedHandler}
										display="full"
										value="Aplicar filtro"
										loading={data.filter.clicked}
									/>
								</Grid>
							</Grid>
				</UsePopOver>
				</span>
			</h2>
			{chartWidth && chartHeight && data.view === "chart" && (
				<div style={{ width: chartWidth, height: chartHeight, minHeight: 400 }}>
					{data.movements.loading && <UseLoading />}
					{!data.movements.loading && chartMemo}
				</div>
			)}
			{data.view === "table" && data.total && (
				<div className="moreInformation" style={{ padding: 20 }}>
					<Grid container spacing={2}>
						<Grid item lg={2} sm={3} xs={12}>
							<h3>Balance</h3>
							Montante: {formatAmount(data.total.balance.amount)}
							<br />
							Transacções: {data.total.balance.count}
						</Grid>
						<Grid item lg={2} sm={3} xs={12}>
							<h3>Total</h3>
							Montante: {formatAmount(data.total.all.amount)}
							<br />
							Transacções: {data.total.all.count}
						</Grid>
						<Grid item lg={2} sm={3} xs={12}>
							<h3>MBWay</h3>
							Montante: {formatAmount(data.total.mbway.amount)}
							<br />
							Transacções: {data.total.mbway.count}
						</Grid>
						<Grid item lg={2} sm={3} xs={12}>
							<h3>QRKoin</h3>
							Montante: {formatAmount(data.total.stripe.amount)}
							<br />
							Transacções: {data.total.stripe.count}
						</Grid>
						<Grid item lg={2} sm={3} xs={12}>
							<h3>Comissão</h3>
							Montante: {formatAmount(data.total.commission.amount)}
						</Grid>
						{!data.movements.loading && data.movements.table.length > 0 && (
							<Grid item lg={2} sm={3} xs={12}>
								<UseExportToExcel
									rows={data.movements.table}
									columns={columns}
								/>
							</Grid>
						)}
					</Grid>
				</div>
			)}
			{chartWidth && chartHeight && data.view === "table" && tableMemo}
		</WrapperStyled>
	);
};

export default PageLayout(Movements);
