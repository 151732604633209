import React from "react";
import PropTypes from "prop-types";
import useWindowSize from "./useWindowSize";
import { removeFromDomByClassName } from "./utility";

const useBlanket = (
	open,
	closeHandler,
	zIndex = 18,
	className = "blanketToRemove",
	opacity = 0.5
) => {
	const { width, height } = useWindowSize();
	React.useEffect(() => {
		removeFromDomByClassName(className);
		if (open) {
			let root = document.getElementById("root");
			let blanket = document.createElement("div");
			blanket.classList.add(className);
			blanket.setAttribute(
				"style",
				`position:fixed;top:0;left:0;z-index:${zIndex};height:${height}px;width:${width}px;display:block;background:rgba(0,0,0,${opacity})`
			);
			blanket.addEventListener("click", () => closeHandler(false));
			root.append(blanket);
		}
	}, [open, closeHandler, className, height, width, opacity, zIndex]);
};

export default useBlanket;

useBlanket.propTypes = {
	open: PropTypes.bool.isRequired,
	closeHandler: PropTypes.func.isRequired,
	zIndex: PropTypes.number,
	className: PropTypes.string,
	opacity: PropTypes.number,
};
