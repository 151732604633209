import React from "react";
import { Avatar, Grid, Stack } from "@mui/material";

import UseButton from "./useButton";
import UsePopOver from "./usePopOver";
import { signOut } from "../firebase";
import { AuthContext } from "./authContextProvider";
import { useNavigate } from "react-router-dom";
import { Current } from "../theme";
import UseDialog from "./useDialog";

const UserInformation = () => {
  const navigate = useNavigate();
  const dialogRef = React.useRef();
  const { user, setAuthLoading } = React.useContext(AuthContext);
  const [isOpen, setIsOpen] = React.useState(false);
  const openPopOverClickedHandler = () => setIsOpen((prev) => !prev);

  const SignOutHandler = () => {
    setAuthLoading(true);
    signOut().catch(() => {});
  };

  return (
    <div className="userInformation">
     <span
      onClick={openPopOverClickedHandler}
      className={isOpen ? "isOpen" : ""}
    >
      Conta
    </span>
    <UsePopOver isOpen={isOpen} toggler={setIsOpen} top={13}>
      <Stack
        direction="column"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Avatar sx={{ bgcolor: Current.primaryHASH, width: 100, height: 100,margin:'20px 0' }}>
          Conta
        </Avatar>
        {user.displayName && <Grid sx={{ fontSize:16,fontWeight:'bold' }}>{user.displayName}</Grid>}
        {user.email && <Grid sx={{ fontSize:16,fontWeight:'bold' }}>{user.email}</Grid>}
        <UseButton
          clicked={() => navigate("/profile")}
          value="Perfil"
          type="button"
          skin="primary"
          size="md"
          display="full"
        />
        <UseButton
          clicked={()=>{
            setIsOpen();
            dialogRef.current.Open()
          }}
          value="Sair"
          type="button"
          skin="danger"
          size="md"
          display="full"
        />
      </Stack>
    </UsePopOver>

    <UseDialog ref={dialogRef} title="Confirmar saída?" size="sm">
        <div className="body alert">
          Tem certeza de que deseja sair?
        </div>
        <div className="footer">
        <UseButton
          clicked={SignOutHandler}
          value="Sair"
          type="button"
          skin="danger"
          size="md"
          style={{marginLeft:'auto'}}
        />
        </div>
      </UseDialog>
    </div>
  );
};
export default UserInformation;
