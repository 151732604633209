import React from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";

const nullable =
  (propType) =>
  (props, propName, ...rest) =>
    props[propName] === null ? null : propType(props, propName, ...rest);

const UseCheckbox = ({
  label,
  value = false,
  onChange,
  labelPlacement = "right",
  round = true,
}) => {
  let inputElement = (
    <div>
      <input type="checkbox" onChange={onChange} checked={value} />
      <span className={`slider ${round ? "round" : ""}`}></span>
    </div>
  );
  let checkboxElement = (
    <UseCheckboxStyled isLeft={labelPlacement === "right"}>
      {inputElement}
      <span>{label}</span>
    </UseCheckboxStyled>
  );
  if (labelPlacement === "left")
    checkboxElement = (
      <UseCheckboxStyled isLeft={labelPlacement === "right"}>
        <span>{label}</span>
        {inputElement}
      </UseCheckboxStyled>
    );

  return checkboxElement;
};

export default UseCheckbox;

UseCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: nullable(PropTypes.bool.isRequired),
  labelPlacement: PropTypes.oneOf(["left", "right"]),
  round: PropTypes.bool,
};

export const UseCheckboxStyled = Styled.label`
  ${(props) => {
    let theme = props.theme;

    let margin = `margin-left:${theme.gSM}`;
    if (props.isLeft) margin = `margin-right:${theme.gSM}`;
    return `
    display:flex;
    cursor:pointer;
    align-items:center;
    height: calc(100% - 16px);

      >div{
        position: relative;
        display: inline-block;
        width: 32px;
        height: 20px;
        ${margin};

        input { 
          opacity: 0;
          width: 0;
          height: 0;
        }
        
        .slider{
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(${theme.black},0.0625);
          -webkit-transition: .4s;
          transition: .4s cubic-bezier(0,1,0.5,1);
          border-radius: 4px;
        }
        
        .slider:before {
          position: absolute;
          content: "";
          height: 16px;
          width: 16px;
          left: 2px;
          bottom: 2px;
          background-color: white;
          -webkit-transition: .4s;
          transition: .4s cubic-bezier(0,1,0.5,1);
          border-radius: 3px;
        }
        
        input:checked + .slider {
          background-color: rgba(${theme.green},1);
        }

        input:checked + .slider:before {
          -webkit-transform: translateX(12px);
          -ms-transform: translateX(12px);
          transform: translateX(12px);
        }

        .slider.round {
          border-radius: 34px;
        }
        
        .slider.round:before {
          border-radius: 50%;
        }
      }
    `;
  }}
`;
