import React from "react";
import PropTypes from "prop-types";
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";

import { CustomTooltip } from "./useAreaChart";
import { getShortMonthName } from "./utility";

const UseBarChart = ({ width, data, totalText = "Total" }) => {
  return (
    <BarChart
      width={width}
      height={150}
      data={data}
      margin={{
        top: 10,
        right: 0,
        left: -30,
        bottom: 0,
      }}
    >
      <defs>
        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="10%" stopColor="#1078F0" stopOpacity={0.6} />
          <stop offset="95%" stopColor="#1078F033" stopOpacity={0.7} />
        </linearGradient>
      </defs>
      <CartesianGrid
        strokeDasharray="1 1"
        vertical={false}
        horizontal={false}
      />
      <XAxis dataKey="x" tickFormatter={getShortMonthName} />
      <YAxis />
      <Tooltip totalText={totalText} content={<CustomTooltip />} />
      <Bar dataKey="t" fill="url(#colorUv)" />
    </BarChart>
  );
};

export default UseBarChart;

UseBarChart.propTypes = {
  width: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  totalText: PropTypes.string,
};
