import React from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";

import useWindowSize from "./useWindowSize";

const UsePopOver = ({
  className,
	top = 50,
	right = 0,
	children,
	noAlign = false,
	isOpen,
	toggler,
  minWidth = 320,
})=>{
	const { width, height } = useWindowSize();
	if (!isOpen) return null
  return (
    <>
    <PopOverStyled
      isOpen={isOpen}
      width={minWidth}
      className={className}
      top={top}
      right={right}
      noAlign={noAlign}
      Blanket
    >
      <div className="container">{children}</div>
    </PopOverStyled>
    <div onClick={()=>toggler()} style={{position:'fixed',top:0,left:0,zIndex:15,display:'block',background:'rgba(0,0,0,0)',height:`${height}px`,width:`${width}px`}}></div>
    </>
  );
};

export default UsePopOver;

UsePopOver.propTypes = {
  isOpen: PropTypes.bool,
  toggler:PropTypes.func,
  minWidth: PropTypes.number,
  top: PropTypes.number,
  right: PropTypes.number,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  noAlign: PropTypes.bool,
};

const PopOverStyled = Styled.div`
  ${(props) => {
    let theme = props.theme;

    let containerDisplay = "none";
    if (props.isOpen) containerDisplay = "block";

    return `
    margin-left:${props.noAlign ? "unset" : "auto"};
    position:relative;
    >.isOpen{
      z-index:20!important;;
    }

    .container{
        position:absolute;
        display:${containerDisplay};
        top:${props.top}px;
        right:${props.right}px;
        background:rgb(${theme.white});
        border:1px solid rgba(${theme.black},0.063);
        padding:${theme.gXL};
        border-radius:5px 0 5px 5px;
        width:calc(${props.width}px - ${theme.gXL} - ${theme.gXL} - 2px);
        color:rgb(${theme.secondary});
        z-index:20!important;

        -webkit-box-shadow: -3px 4px 8px -1px rgba(0,0,0,0.19); 
        box-shadow: -3px 4px 8px -1px rgba(0,0,0,0.19);
    }
  `;
  }}
`;
