import React from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import { Tooltip } from "@mui/material";

import { calculateColumn, valueFromNestedString } from "./utility";

const getColumns = (columns, row, rowClick) => {
	return columns.map((column, index) => {
		const value = valueFromNestedString(row, column.id);
		return (
			<TableCell key={index} align={column.align} onClick={()=>{
				if (!rowClick || column.id === "action") return null;
				rowClick(row, 'byLocation');
				// rowClickHandler(row, "byLocation")
			}}>
				<>
					{column.id !== "action" && column.format
						? column.format(value)
						: value}
					{column.id === "action" &&
						column.actions.length > 0 &&
						column.actions.map((item, index) => {
							let displayIcon = null;
							if (item.display === "icon" || item.display === "both")
								displayIcon = item.icon;

							let displayText = null;
							if (item.display === "text" || item.display === "both")
								displayText = item.text;

							let classElement = "action text";
							if (item.display === "icon") classElement = "action icon";
							if (item.display === "both") classElement = "action text icon";


							let actionElement = <span onClick={() => item.click(row)} className={`${item.className} ${classElement}`}>{displayIcon}{displayText}</span>
							if(item.display === "icon"){
								actionElement = <Tooltip title={item.text} placement="top" arrow key={index}>{actionElement}</Tooltip>
							}
							return actionElement
						})}
				</>
			</TableCell>
		);
	});
};

const UseTable = ({
	data,
	tableWidth = "100%",
	tableHeight,
	columns,
	className = "",
	addedColumn,
	headerHeight = 54.77,
	columnsHeight = 58.52,
	paginationHeight = 52,
	rowClick,
	firstLabelRows,
	firstLabelRowsData,
	secondLabelRows,
	secondLabelRowsData,
	defaultOrderBy = "",
}) => {
	const { tHeight, limit } = calculateColumn(
		tableHeight,
		columnsHeight,
		addedColumn ? headerHeight + 53 : headerHeight,
		paginationHeight
	);

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(limit);

	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState(defaultOrderBy);

	// needed if search function is open
	// const [searched, setSearched] = React.useState("");
	// let columnSearchBy = columns.filter((colum) => colum.searchBy);

	const createSortHandler = (property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const tableSort = (array, comparator) => {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	};

	const descendingComparator = (a, b, orderBy) => {
		let first = valueFromNestedString(a, orderBy);
		let second = valueFromNestedString(b, orderBy);
		if (second < first) return -1;
		if (second > first) return 1;
		return 0;
	};

	const getComparator = (order, orderBy) => {
		return order === "desc"
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	};

	React.useEffect(() => {
		setRowsPerPage(limit);
	}, [limit]);

	const handleChangePage = (event, newPage) => setPage(newPage);
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const rowClickHandler = (row, displayOn) => {
		if (!rowClick) return null;
		rowClick(row, displayOn);
	};

	return (
		<Paper
			sx={{
				width: tableWidth,
				overflow: "hidden",
			}}
			className={`customTable ${className}`}
		>
			<TableContainer sx={{ height: `${tHeight}px` }}>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						{addedColumn && (
							<TableRow>
								{addedColumn.map((column, i) => (
									<TableCell
										key={i}
										align={column.align}
										colSpan={column.colSpan}
										className={`noBorderBottom ${column.className}`}
									>
										<b>{column.label}</b>
									</TableCell>
								))}
							</TableRow>
						)}
						<TableRow>
							{columns.map((column, index) => (
								<TableCell
									key={index}
									align={column.align}
									sortDirection={orderBy === column.id ? order : false}
									style={{
										minWidth: column.minWidth,
										fontWeight: "bold",
										width: column.width,
									}}
									className={column.className}
									rowSpan={2}
								>
									{column.orderBy ? (
										<TableSortLabel
											active={orderBy === column.id}
											direction={orderBy === column.id ? order : "asc"}
											onClick={() => createSortHandler(column.id)}
										>
											{column.label}
											{orderBy === column.id && (
												<Box component="span" sx={visuallyHidden}>
													{order === "desc"
														? "sorted descending"
														: "sorted ascending"}
												</Box>
											)}
										</TableSortLabel>
									) : (
										column.label
									)}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{tableSort(data, getComparator(order, orderBy))
							// needed if search function is open
							// .filter((row) => {
							//   let toReturn = true;
							//   columnSearchBy.forEach((element) => {
							//     let searchedItem = valueFromNestedString(row, element.id);
							//     let inputItem = searched;

							//     if (typeof searchedItem === "string") {
							//       searchedItem = searchedItem.toLowerCase();
							//       inputItem = inputItem.toLowerCase();
							//     } else {
							//       searchedItem = searchedItem.toString();
							//       inputItem = inputItem.toString();
							//     }

							//     let item = searchedItem.includes(inputItem);
							//     toReturn = toReturn && item;
							//     if (item) return;
							//   });
							//   return toReturn;
							// })
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((row, index) => {
								let isOpenElement = null;
								let selectedCompany =
									row.id === firstLabelRows ? "openedRow" : "";
								if (
									firstLabelRows === row.id &&
									firstLabelRowsData.length > 0
								) {
									// table for detail by location
									isOpenElement = tableSort(
										firstLabelRowsData,
										getComparator(order, orderBy)
									).map((locationRow, index) => {
										let selectedLocation =
											locationRow.id === secondLabelRows ? "openedRow" : "";
										let toReturn = (
											<TableRow
												key={locationRow.id + index}
												className={`rows firstLabel ${selectedLocation}`}
												onClick={() => {
													rowClickHandler(locationRow, "byDevice")
												}}
											>
												{getColumns(columns, locationRow)}
											</TableRow>
										);
										let byDeviceIsOpen = null;
										if (
											secondLabelRows === locationRow.id &&
											secondLabelRowsData.length > 0
										) {
											// table for detail by location
											byDeviceIsOpen = tableSort(
												secondLabelRowsData,
												getComparator(order, orderBy)
											).map((deviceRow, index) => (
												<TableRow
													key={deviceRow.id + index}
													className="rows secondLabel"
												>
													{getColumns(columns, deviceRow)}
												</TableRow>
											));
										}
										return [toReturn, byDeviceIsOpen];
									});
								} else if (
									firstLabelRows === row.id &&
									firstLabelRowsData.length === 0
								) {
									isOpenElement = (
										<TableRow key={index + firstLabelRows}>
											<TableCell colSpan={columns.length}>
												Não encontrámos qualquer registo nesta empresa.
											</TableCell>
										</TableRow>
									);
								}
								let rowToReturn = (
									<TableRow
										hover
										role="checkbox"
										tabIndex={-1}
										key={index}
										className={
											index % 2 === 0
												? `rows even ${selectedCompany}`
												: `rows odd ${selectedCompany}`
										}
										// onClick={() => {
										// 	console.log(row)
										// 	rowClickHandler(row, "byLocation")
										// }}
									>
										{getColumns(columns, row, rowClick)}
									</TableRow>
								);
								return [rowToReturn, isOpenElement];
							})}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[]}
				component="div"
				count={data.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Paper>
	);
};

export default UseTable;

UseTable.propTypes = {
	data: PropTypes.array.isRequired,
	tableWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	tableHeight: PropTypes.number.isRequired,
	columns: PropTypes.array.isRequired,
	addedColumn: PropTypes.array,
	className: PropTypes.string,
	headerHeight: PropTypes.number,
	columnsHeight: PropTypes.number,
	paginationHeight: PropTypes.number,
	rowsDetailOf: PropTypes.string,
	rowClick: PropTypes.func,
	detailData: PropTypes.array,
	defaultOrderBy: PropTypes.string,
};
