import React from "react";
import PropTypes from "prop-types";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import DownloadIcon from "@mui/icons-material/Download";

import UseButton from "./useButton";
import { dateTimeFormat } from "./utility";

const formattedData = (rows, columns) => {
	let toReturn = [];

	for (let row of rows) {
		let objToPush = {};
		for (let column of columns) {
			let title;
			switch (column.id) {
				case "totalAmount":
					title = "Montante de total";
					break;
				case "count":
					title = "Total de transacções";
					break;
				case "mbwayAmount":
					title = "Montante MBWay";
					break;
				case "mbwayCount":
					title = "Total de transacções MBWay";
					break;
				case "stripeAmount":
					title = "Montante QRKoin";
					break;
				case "stripeCount":
					title = "Total de transacções QRKoin";
					break;
				default:
					title = column.label;
					break;
			}

			let value = row[column.id];
			if (column.format) {
				value = Number(Math.abs(row[column.id]));
				if (
					column.id === "mbwayAmount" ||
					column.id === "stripeAmount" ||
					column.id === "totalAmount" ||
					column.id === "commission"
				) {
					value = Number(Math.abs(row[column.id]) / 100).toFixed(2);
				}
			}
			objToPush[title] = column.id === "name" ? value : Number(value);
		}
		toReturn.push(objToPush);
	}

	return toReturn;
};

const UseExportToExcel = ({ rows, columns }) => {
	const exportToXLSX = () => {
		const ws = XLSX.utils.json_to_sheet(formattedData(rows, columns));
		const wb = { Sheets: { Movimentos: ws }, SheetNames: ["Movimentos"] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		const data = new Blob([excelBuffer], {
			type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
		});
		FileSaver.saveAs(data, `Movimentos ${dateTimeFormat(new Date())}.xlsx`);
	};
	return (
		<UseButton
			clicked={exportToXLSX}
			value="Baixar"
			type="button"
			skin="primary"
			size="md"
			display="full"
			icons={{ icon: <DownloadIcon /> }}
		/>
	);
};

export default UseExportToExcel;

UseExportToExcel.propTypes = {
	rows: PropTypes.array.isRequired,
	columns: PropTypes.array.isRequired,
};
