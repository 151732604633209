import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import EventIcon from "@mui/icons-material/Event";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

const UseDatePicker = ({ name, label, value, onChange, minWidth = "100%" }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        name={name}
        label={label}
        openTo="day"
        inputFormat="yyyy-MM-dd"
        views={["year", "month", "day"]}
        value={value}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            sx={{ width: minWidth }}
            className="customDatepicker"
            size="small"
            {...params}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={handleOpen}>
                    <EventIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default UseDatePicker;

UseDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  minWidth: PropTypes.string,
};
