import React from "react";
import Styled from "styled-components";
import PropTypes from "prop-types";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import Slider from "@mui/material/Slider";
import UploadIcon from "@mui/icons-material/Upload";

import "react-image-crop/dist/ReactCrop.css";

import UseButton from "./useButton";
import { cropImage } from "./cropImage";

const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 40,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
};

const UseUploadImage = ({
  aspect = 2 / 1,
  buttonText = "Carregar",
  upload,
}) => {
  const imgRef = React.useRef(null);
  const buttonRef = React.useRef(null);
  const [imgSrc, setImgSrc] = React.useState("");
  const [crop, setCrop] = React.useState();

  const [completedCrop, setCompletedCrop] = React.useState();
  const [scale, setScale] = React.useState(1);
  const [rotate, setRotate] = React.useState(0);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      // Makes crop preview, scale and rotate update between images.
      setCrop(undefined);
      setScale(1);
      setRotate(0);

      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onImageLoad = (e) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  React.useEffect(() => {
    let isMounted = true;
    if (completedCrop?.width && completedCrop?.height && imgRef.current) {
      cropImage(imgRef.current, completedCrop, scale, rotate).then(
        (croppedImage) => {
          if (isMounted) upload(croppedImage);
        }
      );
    }

    return () => (isMounted = false);
  }, [completedCrop, scale, rotate, upload]);

  return (
    <UploadImageStyled>
      <UseButton
        clicked={() => buttonRef.current.click()}
        type="button"
        skin="primary"
        size="md"
        display="full"
        style={{ marginBottom: 15 }}
      >
        <UploadIcon /> {buttonText}
        <input
          type="file"
          accept="image/*"
          onChange={onSelectFile}
          style={{ display: "none" }}
          ref={buttonRef}
        />
      </UseButton>
      {Boolean(imgSrc) && (
        <>
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => {
              setCompletedCrop(c);
            }}
            aspect={aspect}
          >
            <img
              ref={imgRef}
              alt="Imagem original"
              src={imgSrc}
              style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
          <div className="settings">
            <label htmlFor="rotate-input">Zoom: </label>
            <Slider
              value={typeof scale === "number" ? scale : 0}
              min={1}
              max={4}
              step={1}
              marks
              aria-label="Girar"
              valueLabelDisplay="auto"
              onChange={(e) => setScale(Number(e.target.value))}
            />
            <b>{scale}</b>
          </div>
          <div className="settings">
            <label htmlFor="rotate-input">Girar: </label>
            <Slider
              value={typeof rotate === "number" ? rotate : 0}
              min={0}
              max={360}
              aria-label="Girar"
              valueLabelDisplay="auto"
              onChange={(e) => setRotate(Number(e.target.value))}
            />
            <b>{rotate}</b>
          </div>
        </>
      )}
    </UploadImageStyled>
  );
};

export default UseUploadImage;

UseUploadImage.propTypes = {
  aspect: PropTypes.number,
  buttonText: PropTypes.string,
  upload: PropTypes.func.isRequired,
};

const UploadImageStyled = Styled.div`
    ${(props) => {
      let theme = props.theme;
      return `
            >div.settings{
              display:flex;
              justify-content:center;
              align-items:center;
              margin-bottom:${theme.gLG};
              >label{
                width:60px;
                margin-right:${theme.gMD};
              }
              >b{
                margin-left:${theme.gMD};
                width:40px;
              }
            }
        `;
    }}
`;
