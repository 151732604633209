import React from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";

import { Current } from "../theme";

const UseButton = React.forwardRef(
	(
		{
			value,
			clicked,
			type = "button",
			skin = "primary",
			size = "md",
			display = "default",
			icons,
			loading,
			style,
			children,
		},
		ref
	) => {
		let iconElement = null;
		if (icons && icons.icon) iconElement = icons.icon;

		let theme = Current.primaryHASH;

		let buttonElement = (
			<SubmitStyled
				type="submit"
				ref={ref}
				skin={skin}
				onClick={clicked}
				size={size}
				display={display}
				disabled={skin === "disable"}
				value={value}
				style={{ ...style }}
			/>
		);
		if (type === "button") {
			buttonElement = (
				<ButtonStyled
					ref={ref}
					skin={skin}
					onClick={clicked}
					size={size}
					display={display}
					disabled={skin === "disable"}
					style={{ ...style }}
				>
					{iconElement}
					{value}
					{children && children}
				</ButtonStyled>
			);
		}

		if (loading) {
			buttonElement = (
				<div style={{ position: "relative" }}>
					{buttonElement}
					{loading && (
						<CircularProgress
							size={24}
							sx={{
								color: theme,
								position: "absolute",
								top: "50%",
								left: "50%",
								marginTop: "-12px",
								marginLeft: "-12px",
							}}
						/>
					)}
				</div>
			);
		}
		return buttonElement;
	}
);

export default UseButton;

UseButton.propTypes = {
	value: PropTypes.string,
	clicked: PropTypes.func.isRequired,
	type: PropTypes.oneOf(["button", "submit"]),
	skin: PropTypes.oneOf(["disable", "primary", "secondary", "danger"]),
	size: PropTypes.oneOf(["sm", "md", "lg"]),
	display: PropTypes.oneOf(["default", "full"]),
	icons: PropTypes.object,
	loading: PropTypes.bool,
	style: PropTypes.object,
	children: PropTypes.node,
};

export const SubmitStyled = Styled.input`
  ${(props) => {
		let theme = props.theme;

		let buttonWidth = "fit-content";
		if (props.display === "full") buttonWidth = "100%";

		let buttonPadding = `${theme.gMD}`;
		if (props.size === "sm") {
			buttonPadding = `${theme.gSM}`;
		} else if (props.size === "lg") {
			buttonPadding = `${theme.gLG}`;
		}

		let buttonTextColor = `rgb(${theme.black})`;
		let buttonBackground = `rgba(${theme.primary},1)`;
		let buttonBackgroundHover = `rgba(${theme.primary},0.9)`;
		if (props.skin === "secondary") {
			buttonBackground = `rgba(${theme.secondary},1)`;
			buttonBackgroundHover = `rgba(${theme.secondary},0.9)`;
			buttonTextColor = `rgb(${theme.white})`;
		} else if (props.skin === "danger") {
			buttonBackground = `rgba(${theme.red},1)`;
			buttonBackgroundHover = `rgba(${theme.red},0.9)`;
			buttonTextColor = `rgb(${theme.white})`;
		} else if (props.skin === "disable") {
			buttonBackground = `rgba(${theme.black},0.1)`;
			buttonBackgroundHover = `rgba(${theme.black},0.1)`;
			buttonTextColor = `rgba(${theme.black},0.4)`;
		}

		return `
        border: none;
        -webkit-appearance: none;
        -ms-accelerator: none;
        -moz-appearance: none;
        appearance: none;
        resize: none;
        outline: none;
        cursor:pointer;

        border-radius:4px;
    
        width:${buttonWidth};
        padding:${buttonPadding};

        background:${buttonBackground};
        color:${buttonTextColor};

        :hover{
            background:${buttonBackgroundHover};
            color:${buttonTextColor};
        }
    `;
	}}
`;

export const ButtonStyled = Styled.button`
  ${(props) => {
		let theme = props.theme;

		let buttonWidth = "fit-content";
		if (props.display === "full") buttonWidth = "100%";

		let buttonPadding = `${theme.gMD}`;
		if (props.size === "sm") {
			buttonPadding = `${theme.gSM}`;
		} else if (props.size === "lg") {
			buttonPadding = `${theme.gLG}`;
		}

		let buttonTextColor = `rgb(${theme.black})`;
		let buttonBackground = `rgba(${theme.primary},1)`;
		let buttonBackgroundHover = `rgba(${theme.primary},0.9)`;
		if (props.skin === "secondary") {
			buttonBackground = `rgba(${theme.secondary},1)`;
			buttonBackgroundHover = `rgba(${theme.secondary},0.9)`;
			buttonTextColor = `rgb(${theme.white})`;
		} else if (props.skin === "danger") {
			buttonBackground = `rgba(${theme.red},1)`;
			buttonBackgroundHover = `rgba(${theme.red},0.9)`;
			buttonTextColor = `rgb(${theme.white})`;
		} else if (props.skin === "disable") {
			buttonBackground = `rgba(${theme.black},0.1)`;
			buttonBackgroundHover = `rgba(${theme.black},0.1)`;
			buttonTextColor = `rgba(${theme.black},0.4)`;
		}

		return `
        border: none;
        -webkit-appearance: none;
        -ms-accelerator: none;
        -moz-appearance: none;
        appearance: none;
        resize: none;
        outline: none;
        cursor:pointer;
        display:flex;
        justify-content:center;
        align-items:center;

        border-radius:4px;
    
        width:${buttonWidth};
        padding:${buttonPadding};

        background:${buttonBackground};
        color:${buttonTextColor};

        :hover{
            background:${buttonBackgroundHover};
            color:${buttonTextColor};
        }

        >svg{
          height:20px;
          margin-right:${theme.gMD};
        }
    `;
	}}
`;
