import axios from "axios";

import { getFirebaseToken } from "./firebase";
import { log } from "./logger";

axios.interceptors.request.use(
  async (config) => {
    await getFirebaseToken()
      .then((token) => {
        log("Token", token, "log");
        config.timeout = 10000;
        config.baseURL = process.env.REACT_APP_SERVER_ADDRESS;
        config.headers = { Authorization: "Bearer " + token };
      })
      .catch((err) => Promise.reject(err));
    return config;
  },
  (err) => Promise.reject(err)
);

function handleAxiosErrors (error){
  // The request was made and the server responded with a status code
  if(error.response) return error.response.status
  
  // The request was made but no response was received
  switch (error.code){
    case 'ECONNABORTED':
      return 408
    case 'ERR_NETWORK':
      return 502
    default:
      // We got some other Error
      return 'A solicitação foi feita, mas não retornou nada do servidor.'
    }
}

export const get = (url, params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params,
      })
      .then((response) => resolve(response.data))
			.catch((error) => {
				log(`Error: get----${url}----serverRequests.js`, error)
				if(params) log('----params----', JSON.stringify(params))
				reject(handleAxiosErrors(error))
			})
  });
};

export const getAll = async (url, params, list) => {
  return new Promise((resolve, reject) => {
    let promises = [];
    for (let i = 0; i < list.length; ++i) {
      params.companyId = list[i].id;
      promises.push(get(url, params));
    }

    Promise.all(promises)
      .then((response) => {
        let formateData = [];
        for (let i = 0; i < list.length; ++i) {
          formateData.push({
            companyId: list[i].id,
            name: list[i].name,
            data: response[i],
          });
        }
        resolve(formateData);
      })
			.catch((error) => {
				log(`Error: getAll----${url}----serverRequests.js`, error)
				if(params) log('----params----', JSON.stringify(params))
				if(list) log('----list----', JSON.stringify(list))
				reject(handleAxiosErrors(error))
			})
  });
};

export const put = (url, data, params) => {
  return new Promise((resolve, reject) => {
    axios
      .put(url, data, {
        params,
      })
      .then((response) => resolve(response.data))
			.catch((error) => {
				log(`Error: put----${url}----serverRequests.js`, error)
				if(data) log('----data----', JSON.stringify(data))
				if(params) log('----params----', JSON.stringify(params))
				reject(handleAxiosErrors(error))
			})
  });
};

export const post = (url, data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data)
      .then((response) => resolve(response))
			.catch((error) => {
				log(`Error: post----${url}----serverRequests.js`, error)
				if(data) log('----data----', JSON.stringify(data))
				reject(handleAxiosErrors(error))
			})
  });
};
