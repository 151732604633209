import React from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
import GroupIcon from "@mui/icons-material/Group";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import DevicesOtherIcon from "@mui/icons-material/DevicesOther";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { NavLink } from "react-router-dom";

const Menu = ({ isCollapse, isOpen, height }) => {
	return (
		<MenuStyled isCollapse={isCollapse} height={height} isOpen={isOpen}>
			<NavLink to="/clients">
				<GroupIcon />
				Clientes
			</NavLink>
			<NavLink to="/movements">
				<TrendingUpIcon /> Movimentos
			</NavLink>
			<NavLink to="/devices">
				<DevicesOtherIcon /> Dispositivos
			</NavLink>
			{/* <NavLink to="/managements">
				<ManageAccountsIcon /> Gerências
			</NavLink> */}
		</MenuStyled>
	);
};

export default React.memo(Menu);

Menu.propTypes = {
	isCollapse: PropTypes.bool.isRequired,
	isOpen: PropTypes.bool.isRequired,
	height: PropTypes.number.isRequired,
};

const MenuStyled = Styled.div`
  ${(props) => {
		let theme = props.theme;
		return `
        margin-left:auto;
        display:flex;
        z-index: 18;

        >a{
            display:flex;
            align-items:center;
            margin:0 ${theme.gSM};
            color:rgba(${theme.secondary},1);
            >svg{
                margin-right: ${theme.gSM};
                font-size: 22px;
            }
            :hover{
                color:rgba(${theme.primary},1);
            }
        }
        >a.active{
            color:rgba(${theme.primary},1);
        }

        >a:first-child{
            margin-left:0;
        }
        >a:last-child{
            margin-right:0;
        }


        ${
					props.isCollapse &&
					`
            position:absolute;
            height:${props.height - theme.HFHeight + 1}px;
            background:rgba(${theme.primary},1);
            width:250px;
            top:0;
            left:0;
            display:none;
            padding:${theme.HFHeight + 11}px 0 0 0;
            flex-direction:column;

            ${
							props.isOpen &&
							`
              display:flex;


              >a{
                display:flex;
                align-items:center;
                margin:unset;
                color:rgba(${theme.black},1);
                padding:${theme.gMD};
                :hover{
                    background:rgba(${theme.white},0.9);
                }
                }
                >a.active{
                    background:rgba(${theme.white},1);
                    color:rgba(${theme.primary},1);
                }

              `
						}
        `
				}
    `;
	}}
`;
