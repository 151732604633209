import React from "react";
import PropTypes from "prop-types";

import UseButton from "./useButton";
import UseInput from "./useInput";
import UseTextArea from "./useTextArea";
import { updateInputChangeSingle, updateObject, typePropsValidator } from "./utility";
import UseAutocomplete from "./useAutocomplete";

const DevicePopup = ({ locations, selected, type, success }) => {
	const [device, setDevice] = React.useState({
		name: {
			label: "Dispositivo",
			validation: {
				required: true,
				min: 4,
			},
			valid: false,
			touched: false,
			value: "",
			element: {
				name: "name",
				type: "text",
				placeholder: "Nome da dispositivo",
				autoComplete: "Off",
				onChange: (e) => inputChangeHandler(e, "name"),
			},
		},
		observations: {
			label: "Observações",
			validation: {
				required: true,
				min: 1,
				max: 30,
			},
			valid: false,
			touched: false,
			value: "",
			element: {
				name: "observations",
				type: "text",
				placeholder: "Observações",
				autoComplete: "Off",
				onChange: (e) => inputChangeHandler(e, "observations"),
			},
		},
		locationId: {
			label: "Localização",
			validation: {
				required: false,
			},
			valid: false,
			touched: false,
			value: null,
			element: {
				multiple: false,
				searchable: true,
				onChange: (e, data) => inputChangeHandler(data, "locationId"),
				className: "noMargin marginBottom",
			},
		},
		submit: false,
	});

	// update if popup is for edit
	React.useEffect(() => {
		if (type === "edit" && selected)
			setDevice((prev) => {
				let obj = updateObject(prev, {
					name: updateObject(prev.name, {
						value: selected.name,
						valid: true,
					}),
					observations: updateObject(prev.observations, {
						value: selected.observations,
						valid: true,
					}),
					locationId: updateObject(prev.locationId, {
						value: selected.locationId,
						valid: true,
					}),
				});
				return obj;
			});
	}, [selected, type]);

	const inputChangeHandler = (e, key) =>
		setDevice((prev) =>
			updateInputChangeSingle(
				prev,
				key === "locationId" ? e : e.target.value,
				key
			)
		);

	const submitClickHandler = () => {
		let toSend = {
			name: device.name.value,
			observations: device.observations.value,
		};
		if (type === "add") {
			// add device handler
			if(device.locationId.value) toSend.locationId = device.locationId.value.id;
			
		} else if (type === "edit") {
			// edit device handler
		} else {
			// delete device handler
			let id = selected.id;
		}
	};

	let buttonText = null;
	let buttonSkin = null;
	let bodyElement = (
		<div className="body">
			{type !== "edit" && locations.length > 0 && device.locationId && (
				<UseAutocomplete
					label={device.locationId.label}
					options={locations}
					selected={device.locationId.value}
					onChange={device.locationId.element.onChange}
					multiple={device.locationId.element.multiple}
					searchable={device.locationId.element.searchable}
					className={device.companyId.element.className}
				/>
			)}
			{device.name && <UseInput config={device.name} />}
			{device.observations && <UseTextArea config={device.observations} />}
		</div>
	);
	if (type === "edit") {
		buttonText = `Guardar`;
		buttonSkin = "secondary";
	} else if (type === "delete") {
		buttonText = `Apagar`;
		buttonSkin = "danger";
		bodyElement = (
			<div className="body">
				Tem a certeza que quer eliminar {selected.name}?
			</div>
		);
	} else {
		buttonText = `Adicionar`;
		buttonSkin = "primary";
	}
	if (!device.submit && type !== "delete") buttonSkin = "disable";

	return (
		<div>
			{bodyElement}
			<div className="footer">
				<UseButton
					clicked={submitClickHandler}
					value={buttonText}
					type="button"
					skin={buttonSkin}
					size="md"
					display="default"
					style={{ marginLeft: "auto" }}
				/>
			</div>
		</div>
	);
};

export default DevicePopup;

DevicePopup.propTypes = {
	selected: PropTypes.object,
	locations: PropTypes.array,
	type: typePropsValidator.isRequired,
	success: PropTypes.func.isRequired,
};
