import React from "react";
import PropTypes from "prop-types";

import UseButton from "./useButton";
import UseInput from "./useInput";
import UseAutocomplete from "./useAutocomplete";
import { updateInputChangeSingle, updateObject, typePropsValidator } from "./utility";

const LocationPopup = ({ companies, selected, type, success }) => {
	const [data, setData] = React.useState({
		companyId: {
			label: "Empresas",
			validation: {
				required: true,
				type: "obj",
			},
			valid: false,
			touched: false,
			value: null,
			element: {
				multiple: false,
				searchable: true,
				onChange: (e) => inputChangeHandler(e, "companyId"),
				className: "noMargin marginBottom",
			},
		},
		name: {
			label: "Localização",
			validation: {
				required: true,
				min: 4,
			},
			valid: false,
			touched: false,
			value: "",
			element: {
				name: "name",
				type: "text",
				placeholder: "Nome da localização",
				autoComplete: "Off",
				onChange: (e) => inputChangeHandler(e, "name"),
			},
		},
		latitude: {
			label: "Latitude",
			validation: {
				required: true,
				min: 4,
			},
			valid: false,
			touched: false,
			value: "",
			element: {
				name: "latitude",
				type: "number",
				placeholder: "Latitude",
				autoComplete: "Off",
				onChange: (e) => inputChangeHandler(e, "latitude"),
			},
		},
		longitude: {
			label: "Longitude",
			validation: {
				required: true,
				min: 4,
			},
			valid: false,
			touched: false,
			value: "",
			element: {
				name: "longitude",
				type: "number",
				placeholder: "longitude",
				autoComplete: "Off",
				onChange: (e) => inputChangeHandler(e, "longitude"),
			},
		},
		submit: false,
	});

	// update if popup is for edit
	React.useEffect(() => {
		if (type === "edit" && selected)
			setData((prev) => {
				let obj = updateObject(prev, {
					companyId: updateObject(prev.companyId, {
						value: selected.companyId,
						valid: true,
					}),
					name: updateObject(prev.name, {
						value: selected.name,
						valid: true,
					}),
					latitude: updateObject(prev.latitude, {
						value: selected.latitude,
						valid: true,
					}),
					longitude: updateObject(prev.longitude, {
						value: selected.longitude,
						valid: true,
					}),
				});

				delete obj.companyId;

				return obj;
			});
	}, [selected, type]);

	const inputChangeHandler = (e, key) =>
		setData((prev) =>
			updateInputChangeSingle(
				prev,
				key === "companyId" ? e : e.target.value,
				key
			)
		);

	const submitClickHandler = () => {
		let toSend = {
			name: data.name.value,
			latitude: data.latitude.value,
			longitude: data.longitude.value,
		};

		if (type === "add") {
			// add location handler
			if(data.companyId.value) toSend.companyId = data.companyId.value.id;
		} else if (type === "edit") {
			// edit location handler
		} else {
			// delete location handler
			let id = selected.id;
		}
	};

	let buttonText = null;
	let buttonSkin = null;
	let bodyElement = (
		<div className="body">
			{type !== "edit" && companies.length > 0 && (
				<UseAutocomplete
					label={data.companyId.label}
					options={companies}
					selected={data.companyId.value}
					onChange={data.companyId.element.onChange}
					multiple={data.companyId.element.multiple}
					searchable={data.companyId.element.searchable}
					className={data.companyId.element.className}
				/>
			)}
			<UseInput config={data.name} />
			<UseInput config={data.latitude} />
			<UseInput config={data.longitude} />
		</div>
	);
	if (type === "edit") {
		buttonText = `Guardar`;
		buttonSkin = "secondary";
	} else if (type === "delete") {
		buttonText = `Apagar`;
		buttonSkin = "danger";
		bodyElement = (
			<div className="body">
				Tem a certeza que quer eliminar {selected.name}?
			</div>
		);
	} else {
		buttonText = `Adicionar`;
		buttonSkin = "primary";
	}
	if (!data.submit && type !== "delete") buttonSkin = "disable";
	return (
		<div>
			{bodyElement}
			<div className="footer">
				<UseButton
					clicked={submitClickHandler}
					value={buttonText}
					type="button"
					skin={buttonSkin}
					size="md"
					display="default"
					style={{ marginLeft: "auto" }}
				/>
			</div>
		</div>
	);
};

export default LocationPopup;

LocationPopup.propTypes = {
	companies: PropTypes.array,
	selected: PropTypes.object,
	type: typePropsValidator.isRequired,
	success: PropTypes.func.isRequired,
};
