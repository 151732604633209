import React from "react";
import PropTypes from "prop-types";
import { onAuthStateChanged, getAuth } from "firebase/auth";

import UseLoading from "./useLoading";
import UseSnackbar from "./useSnackbar";
import { log } from "../logger";
import { post } from "../serverRequest";
import { getErrorMessageWidthStatus } from "./utility";
import { signOut } from "../firebase";

export const AuthContext = React.createContext();

export const AuthContextProvider = ({ children }) => {
  const snackbarRef = React.useRef();
  const [user, setUser] = React.useState(null);
  const [authLoading, setAuthLoading] = React.useState(true);

  // type = 'errors', 'success', 'warning', 'info' and action = 'normal', 'notification', 'logout', 'push'
  const snackbar = React.useCallback(
    (message, types = "errors", action = "normal") =>
      snackbarRef.current.Open(message, types, action),
    []
  );

  React.useEffect(() => {
    onAuthStateChanged(getAuth(), (firebaseUser) => {
      log("User is " + (firebaseUser ? "logged." : "not logged."));
      if (firebaseUser) {
        post("/auth", { email: firebaseUser.email })
          .then(() => {
            setUser(firebaseUser);
            setAuthLoading(false);
          })
          .catch((status) => {
            snackbar(getErrorMessageWidthStatus(status));
            signOut().catch(() => {});
          });
      } else {
        setAuthLoading(false);
        setUser(null);
      }
    });
  }, [snackbar]);

  return (
    <AuthContext.Provider
      value={{
        user,
        setAuthLoading,
        snackbar,
      }}
    >
      {authLoading ? <UseLoading /> : children}
      <UseSnackbar ref={snackbarRef} />
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
