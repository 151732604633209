import React from "react";
import GroupIcon from "@mui/icons-material/Group";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Grid } from "@mui/material";

import PageLayout from "../components/pageLayout";
import UseAreaChart from "../components/useAreaChart";
import UseBarChart from "../components/useBarChart";
import UseLoading from "../components/useLoading";
import UseDatePicker from "../components/useDatePicker";
import UsePopOver from "../components/usePopOver";
import UseButton from "../components/useButton";
import UseEmpty from "../components/useEmpty";
import { WrapperStyled } from "../theme";
import { useSizeWithChild } from "../components/useWindowSize";
import { get } from "../serverRequest";
import {
  dateFormat,
  getErrorMessageWidthStatus,
  getFirstDayOfYear,
  updateObject,
} from "../components/utility";
import { log } from "../logger";
import { AuthContext } from "../components/authContextProvider";
import UseTooltip from "../components/UseTooltip";

const countTotalNumberOfUser = (list) => {
  let total = 0;
  return list.map((item) => {
    total = total + item.y;
    return {
      ...item,
      t: total,
    };
  });
};

const Clients = () => {
  const wrapDivRef = React.useRef();
  const { snackbar } = React.useContext(AuthContext);

  const [isOpen, setIsOpen] = React.useState(false);
  const filterOpenClickHandler = () => setIsOpen((prev) => !prev);

  const wrapDiv = useSizeWithChild(wrapDivRef);

  let chartHeight, chartWidth;
  if (wrapDiv.height && wrapDiv.width) {
    chartHeight = wrapDiv.height + 16;
    chartWidth = wrapDiv.width;
  }

  const [chart, setChart] = React.useState({
    list: [],
    total: [],
    reload: true,
    loading: true,
    filter: {
      clicked: false,
      from: getFirstDayOfYear(),
      to: dateFormat(new Date()),
    },
  });

  // get clients/chart
  React.useEffect(() => {
    let isMounted = true;
    if (chart.reload && chart.loading) {
      get("clients/chart", {
        from: chart.filter.from + " 00:00:00",
        to: chart.filter.to + " 23:59:59",
      })
        .then((response) => {
          log("Success: get-clients/chart--clients.js", response, "log");
          if (isMounted) {
            setChart((prev) =>
              updateObject(prev, {
                list: response,
                total: countTotalNumberOfUser(response),
                reload: false,
                loading: false,
                filter: updateObject(prev.filter, {
                  clicked: false,
                }),
              })
            );
          }
        })
        .catch((status) => {
          log("Error: get-clients/chart--clients.js", status);
          setChart((prev) =>
            updateObject(prev, {
              reload: false,
              loading: false,
              filter: updateObject(prev.filter, {
                clicked: false,
              }),
            })
          );
          snackbar(getErrorMessageWidthStatus(status));
        });
    }
    return () => (isMounted = false);
  }, [chart.reload, chart.loading, chart.filter, snackbar]);

  const handleChangeDate = (value, key) =>
    setChart((prev) =>
      updateObject(prev, {
        filter: updateObject(prev.filter, {
          [key]: dateFormat(value),
        }),
      })
    );

  const filterClickedHandler = () => {
    setChart((prev) =>
      updateObject(prev, {
        reload: true,
        loading: true,
        filter: updateObject(prev.filter, {
          clicked: true,
        }),
      })
    );
    setIsOpen(false)
  };

  return (
    <WrapperStyled ref={wrapDivRef}>
      <h2>
        <GroupIcon /> Clientes

        <span style={{marginLeft: 'auto'}}>
        <UseTooltip title='Filtros' placement='left'>
          <span onClick={filterOpenClickHandler} className={isOpen ? "isOpen" : ""}>
              <FilterAltIcon />
          </span>
        </UseTooltip>
        <UsePopOver
        isOpen={isOpen}
        toggler={setIsOpen}
        top={25}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <UseDatePicker
                name="from"
                label="From"
                value={chart.filter.from}
                onChange={(value) => handleChangeDate(value, "from")}
              />
            </Grid>
            <Grid item xs={12}>
              <UseDatePicker
                name="to"
                label="To"
                value={chart.filter.to}
                onChange={(value) => handleChangeDate(value, "to")}
              />
            </Grid>
            <Grid item xs={12}>
              <UseButton
                type="button"
                skin={chart.filter.clicked ? "disable" : "primary"}
                clicked={filterClickedHandler}
                display="full"
                value="Aplicar filtro"
                loading={chart.filter.clicked}
              />
            </Grid>
          </Grid>
        </UsePopOver>
        </span>
      </h2>

      <div className="moreInformation" style={{ minHeight: 198 }}>
        <Grid container spacing={2} sx={{ padding: 2 }}>
          <Grid item xs={12}>
            {chart.loading && chart.total.length === 0 && <UseLoading />}
            {!chart.loading && chart.total.length === 0 && (
              <UseEmpty text="Não encontramos nenhum clientes com estas configurações de filtro." />
            )}
            {chart.total.length > 0 && !chart.loading && (
              <UseBarChart
                width={chartWidth - 32}
                data={chart.total}
                totalText="Total de clientes"
              />
            )}
          </Grid>
        </Grid>
      </div>

      {chartWidth && chartHeight && (
        <div style={{ width: chartWidth, height: chartHeight }}>
          {chart.loading && chart.list.length === 0 && <UseLoading />}
          {!chart.loading && chart.list.length === 0 && (
            <UseEmpty text="Não encontramos nenhum clientes com estas configurações de filtro." />
          )}
          {chart.list.length > 0 && !chart.loading && (
            <UseAreaChart
              width={chartWidth}
              height={chartHeight}
              data={chart.list}
              totalText="Número de novos clientes"
            />
          )}
        </div>
      )}
    </WrapperStyled>
  );
};

export default PageLayout(Clients);
