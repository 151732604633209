import Styled, { createGlobalStyle } from "styled-components";

let headerFooter = 70;

export const Current = {
	primary: "255, 193, 7",
	secondary: "55, 71, 79",
	primaryHASH: "#fec109",
	secondaryHASH: "#37474f",

	white: "255, 255, 255",
	black: "0,0,0",
	orange: "255, 87, 34",
	red: "248, 25, 25",
	green: "81, 187, 131",
	blue: "0,146,181",
	gray: "34,34,34",

	fSM: `font-size: 12px`,
	fDF: `font-size: 14px`,
	fMD: `font-size: 16px`,
	fLG: `font-size: 18px`,
	fXL: `font-size: 22px`,
	fXLL: `font-size: 26px`,
	fXXL: `font-size: 32px`,
	fXXXL: `font-size: 38px`,

	gSM: "5px",
	gMD: "10px",
	gLG: "15px",
	gXL: "20px",

	fontPrimary: `font-family: 'Roboto', sans-serif`,
	fontSecondary: `font-family:  Arial`,
	fontMono: `font-family:  'Roboto Mono', monospace`,

	mMX_XXS: "@media (max-width: 380px)",
	mMX_XS: "@media (max-width: 500px)",
	mMX_XSM: "@media (max-width: 560px)",
	mMX_DF: "@media (max-width: 620px)",
	mMX_SM: "@media (max-width: 768px)",
	mMX_MD: "@media (max-width: 991px)",
	mMX_LG: "@media (max-width: 1100px)",
	mMX_XL: "@media (max-width: 1200px)",

	HFHeight: headerFooter,

	footer: `
    display:flex;
    flex-grow:0;
    height:${headerFooter}px;
    min-height:${headerFooter}px;
    flex-direction: row;
    align-items:center;
    padding:0 10px;
    >span{
      cursor:pointer;
      margin-left:7px;
      padding-left:7px;
      border-left:1px solid rgba(0,0,0,0.7);
      color:rgba(0,0,0,0.7);
      :hover{
        color:rgba(0,0,0,1);
      }
    }
  `,
};

export const GlobalStyle = createGlobalStyle`
    ${(props) => {
			let theme = props.theme;
			return `
        body, html
          {
              background:rgb(${theme.white})!important;
              height: 100%!important;
              overflow: hidden!important;
              -webkit-overflow-scrolling: touch;
          
              margin: 0!important;
              padding: 0!important;
              min-width: unset!important;
              ${theme.fontPrimary}!important;
              ${theme.fDF}!important;
              line-height: 1.4285em!important;
              color: rgba(${theme.black}, 0.87)!important;
          }
        * 
          {
            margin: 0;
            padding: 0;
          }
        a
          {
              text-decoration: none;
          }
        h1,h2,h3,h4,h5,h6
          {
            ${theme.fontPrimary}!important;
            margin: 0;
            padding: 0;
            line-height:unset!important;
          }
        #root
          {
              height:100%;
              overflow: hidden!important;
          }

          // Input 

          .input{
            margin-bottom:${theme.gXL}!important;
          }

          .error>label{
            color:rgb(${theme.red})!important;
          }
          .error>input{
            border:1px solid rgb(${theme.red})!important;
          }

          // icon left
          .input.icon.left > .placeholder,
          .input.icon.password.left> .placeholder{
            left: 40px!important;
          }
          .input.icon.left >span,
          .input.icon.password.left >span{
              top:1px;
              left:1px;
          }
          .input.icon.left >input{
            width:calc(100% - 52px);
            padding: 0 10px 0 40px;
          }
          // icon right
          .input.icon.right >span,
          .input.password.icon.right >span{
              top:1px;
              right:1px;
          }
          .input.icon.right >input{
            width:calc(100% - 52px);
            padding: 0 40px 0 10px;
          }
          .input.password.icon >input{
            padding: 0 40px 0 40px;
            width:calc(100% - 82px);
          }

          .input.password.icon.right>.showHide{
              left: 1px;
              right:unset!important;
          }

          .datePicker{
            padding-top:20px!important;

            >div{
              flex-grow:1;
            }
          }


          // custom Table MUI
          .customTable th{
            background:rgb(${theme.primary})!important;
          }
          .odd>td
          {
            background:#FFF!important;
          }
          .even>td
          {
            background:#f2f2f5!important;
          }
          .openedRow{
            >td{
              font-weight:bold;
            }
          }
          .rows{
            cursor:pointer;

            :hover>td{
              background:#eaeaea!important;
            }
          }
          .noStripe{
            .odd>td
            {
              background:#FFF!important;
            }
            .even>td
            {
              background:#FFF!important;
            }
            .rows{
              cursor:pointer;
  
              :hover>td{
                background:#eaeaea!important;
              }
            }
            .firstLabel{
              >td{
                background:rgba(0,0,0,0.0530);
              }
              >td:first-child{
                padding-left:25px;
              }
            }
            .secondLabel{
              >td{
                background:rgba(0,0,0,0.0265);
              }
              >td:first-child{
                padding-left:45px;
              }
            }
          }

          .alert{
            color:rgba(${theme.red},1);
            font-weight:bold;
          }

          .customTable .delete{
            cursor:pointer;
            :hover{
              color:rgba(${theme.red},1);
            }
          }
          .customTable .edit{
            cursor:pointer;
            margin-right:5px;
            :hover{
              color:rgba(${theme.orange},1);
            }
          }

          .noBorderBottom{
            border-bottom:0px!important;
          }

          .borderBottom{
            border-bottom:1px solid rgba(${theme.white},0.3)!important;
          }

          .leftBorder{
            border-left:1px solid rgba(${theme.white},0.3);
          }
          .rightBorder{
            border-right:1px solid rgba(${theme.white},0.3);
          }



          .noMargin{
            margin:0!important;
          }
          .marginTop{
            margin-top:20px!important;
          }
          .marginBottom{
            margin-bottom:20px!important;
          }

          .customAutocomplete{
            margin-bottom:${theme.gXL};

            label.MuiInputLabel-formControl{
              top:6px;
            }

            .MuiAutocomplete-inputRoot{
              min-height:50px;
            }

            .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
              border-color:rgba(${theme.black},0.23);
            }

          }


          .customDatepicker {
            margin-bottom:${theme.gSM};

            .MuiInputBase-root{
              height:50px;
            }
          }

          .MuiAvatar-img{
            height:auto!important;
          }

          li.customAutocomplete{
            display: flex;
            justify-content:flex-start!important;
            align-items:center;
            margin:0;
            padding:0;

            img{
              width:30px; 
              margin-right:${theme.gMD};
            }


            span{
            display: flex;
            flex-direction:column;
            justify-content:flex-start!important;
            align-items:flex-start!important;

              em{
                width:100%;
                font-size:10px;
                color:rgba(${theme.black},0.625)!important;
              }

             
            }

            
          }


          // autocomplete dropdown
          .customAutocomplete{
            position:absolute;
            margin:0;
            padding:0;
            top:0;
            left:0;
            list-style:none;
            max-height:15em;
            overflow-y:auto;
            border: 1px solid rgba(0,0,0,0.0625);
            border-radius:4px;
            width:100%;
            left:0;
            top:100%;
            background:rgb(255,255,255);
            z-index:9999!important;
            font-weight: normal;

            .search{
              padding:0;
              border-bottom: 1px solid rgba(0,0,0,0.0625);
              margin-bottom:5px;

              >input{
                width:100%;
                height:30px;
                padding:5px;
                background:none;
                border:none;
                outline:none;
              }
            }

            .option{
              padding: 5px 10px;
              cursor:pointer;
          }

          .option.highlighted{
              color:rgba(255,255,255,1);
              background-color:rgba(25, 133, 248, 1);
          }
          .option.selected{
              background-color:rgba(25, 133, 248, 0.8);
          }

          }

          textarea:focus-within {
            border-color: red;
          }
        `;
		}}
`;

// Outer Wrapper user for every Layouts
export const OuterWrapper = Styled.div`
    ${(props) => {
			let theme = props.theme;
			return `
        background:rgba(${theme.white},1);
        height:100vh;
        width:100%;
        display:flex;
        flex-direction: column;
        overflow:hide;

        >.body{
          flex-grow:1;

          display:flex;
          flex-direction: column;
          overflow:scroll;

          >.page{
            flex-grow:1;
          }

          >.page.profile{
            display:flex;
            justify-content:center;
          }

          >.login{
            flex-grow:1;

            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

           >.box{
            width:380px;

            // footer css only for login page
            >.footer{
              ${theme.footer};
              background:unset!important;
              justify-content:center;
            }
           }
          }
        }
        // header css only for inner pages
        >.header{
          ${theme.footer};
        }
        // footer css only for inner pages
        >div>.footer{
          ${theme.footer};
          border-top:1px solid rgba(0,0,0,0.063);
        }
      `;
		}}
`;

export const SignInStyled = Styled.div`
${(props) => {
	let theme = props.theme;
	return `
    align-self: center;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;

    >h1{
      >img{
        height:120px;
        width:auto;
        margin:${theme.gLG} 0;
      }
      >div{
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
        text-align:center;

        ${theme.fontPrimary};
        color:rgba(${theme.secondary},1);

        >p{
          ${theme.fXXXL};
          >span{
            color:rgba(${theme.primary},1);
          }
        }
        >span{
          ${theme.fSM};
          text-transform: uppercase;
          ${theme.fontMono};
          margin-top:7px;
        }
      }
    }
    >.box{
      margin-top:40px;
      width:380px;
      padding:${theme.gXL};
      background:rgb(${theme.white});
      border-radius:4px;
      box-shadow: 0 1px 20px -4px rgba(${theme.black}, 0.0625);
      border: 1px solid rgba(${theme.black}, 0.0625);

      ${theme.mMX_XS}
      {
          width:90%;
      }

      >h2{
        text-align:center;
        margin-bottom:${theme.gXL};
        color:rgb(${theme.secondary});
        ${theme.fLG};
      }
    }
  `;
}}
`;

export const IframeStyled = Styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  min-height: 480px !important;
`;

export const WrapperStyled = Styled.div`
  ${(props) => {
		let theme = props.theme;
		let gap = theme.gXL;
		let width = `calc(100% - (2*${gap}))`;
		if (props.width) width = `${props.width}px`;
		return `
    height: calc(100% - (2*${gap}));
    width: ${width};
    padding:${gap};
      >h2{
        display:flex;
        align-items:center;
        font-size:16px;
        height:50px;
        border:1px solid rgba(${theme.black}, 0.0625);
        padding:0 0 0 ${theme.gXL};
        background:rgba(${theme.primary},1);

        >svg{
          margin-right:${theme.gMD};
        }

        >span{
          height:100%;
          display:flex;
          align-items:center;
          border-left:1px solid rgba(${theme.black},0.0625);
          ${!props.isSelectedCompany?`margin-left: auto;`:''}
          
          >span{
            height:100%;
            display:flex;
            align-items:center;
            padding:0 ${theme.gXL};
            cursor:pointer;

            :hover{
              background:rgba(${theme.black},0.0625);
            }
          }
          >span.isOpen{
            background:rgba(${theme.black},0.0625);
          }
        }
      }
      >div{
        border:1px solid rgba(${theme.black}, 0.0625);
        border-top:unset;
      }

      ${theme.mMX_SM}{
        width: calc(100% - (2*${gap}));
      }

      ${
				props.width &&
				`
        align-items:center;
        justify-content:center;
        `
			}
    `;
	}}
`;

export const FilterStyled = Styled.div`
${(props) => {
	let theme = props.theme;
	return `
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    border-width:0px;
    padding:${theme.gMD};

    >div{
      border:1px solid rgba(${theme.black},0.0625);
    }
    .filter{
      width: calc(20% - ( 104px / 2));
      padding:${theme.gXL};
    }
    .total{
      width: calc(80% - ( 104px / 2));
      padding:${theme.gXL};
    }

    .filterMovement{
      width: calc(35% - ( 104px / 2));
      padding:${theme.gXL};
    }
    .totalMovement{
      width: calc(65% - ( 104px / 2));
      padding:${theme.gXL};
    }
  `;
}}
`;

export const QrCodeStyled = Styled.div`
${(props) => {
	let theme = props.theme;
	return `
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    padding:25px 0;
    width:100%;

    .clickToCopy{
      padding:10px 0 0 0;
      display:flex;
      justify-content: center;
      align-items: center;
      cursor:pointer;
      >svg{
        color:rgba(${theme.green});
        margin-left:10px;
      }
    }
  `;
}}
`;


export const JsonEditorStyled = Styled.div`
  ${props=>{
    let theme = props.theme;
    return `
    >div>textarea{
      border: 1px solid rgba(${theme.black}, 0.0625);
      overflow: auto;
      outline: none;

      width:100%;
      height:200px;

      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      resize:none;
    }
    >div>textarea.error{
      border: 1px solid rgba(${theme.red}, 1);
    }
    `
  }}
`
