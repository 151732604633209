import React, { useState } from 'react';
import { JsonEditorStyled } from '../theme';
import UseButton from "../components/useButton";

function JSONEditor() {
  const [jsonString, setJsonString] = useState('');
  const [error, setError] = useState(false);

  const handleSave = () => {
    const data = new FormData();
    data.append('file', jsonString);
  for (const value of data.values()) {
    // console.log(value);
  }
    // fetch('/server/upload', {
    //     method: 'POST',
    //     body: data
    // }).then(response => {
    //     console.log(response);
    // }).catch(error => {
    //     console.log(error);
    // });
}

  const handleChange = (event) => {
    setJsonString(event.target.value);
    try {
        let json = JSON.parse(event.target.value);
        if (json && typeof json === "object") {
            let jsonStringified = JSON.stringify(json, undefined, 4);
            setJsonString(jsonStringified);
            setError(false);
        }else {
            throw new Error("Not a valid json object");
        }
    } catch (e) {
        console.log("Error in parsing JSON: ");
        setError(true);
    }
  }



  return (
    <JsonEditorStyled>
      <div className="body">
        <textarea
          className={error ? jsonString !==''?'error':null : null}
          value={jsonString}
          onChange={handleChange}
        />
      </div>
      <div className="footer">
								<UseButton
									type="button"
                  skin={error ? "disable" : "primary"}
									clicked={handleSave}
									display="full"
									value="Guardar"
								/>
      </div>
      </JsonEditorStyled>
  );
}

export default JSONEditor;