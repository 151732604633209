import React from "react";
import { ThemeProvider } from "styled-components";
import { Routes, Route } from "react-router-dom";

import SignIn from "./pages/signIn"; 
import Clients from "./pages/clients";
import Movements from "./pages/movements";
import Devices from "./pages/devices";
import Managements from "./pages/managements";
import NotFound from "./pages/notFound";
import Profile from "./pages/profile";
import PrivateRoute from "./components/privateRoute";
import { Current, GlobalStyle } from "./theme";
import { AuthContextProvider } from "./components/authContextProvider";

const App = () => {
  return (
    <ThemeProvider theme={Current}>
      <AuthContextProvider>
        <Routes>
          <Route path="/" element={<SignIn />} />

          <Route element={<PrivateRoute />}>
            <Route exact path="/clients" element={<Clients />} />
            <Route path="/movements" element={<Movements />} />
            <Route path="/devices" element={<Devices />} />
            <Route path="/managements" element={<Managements />} />
            <Route path="/profile" element={<Profile />} />
          </Route>

          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthContextProvider>
      <GlobalStyle />
    </ThemeProvider>
  );
};

export default App;
