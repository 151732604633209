import React from "react";
import PropTypes from "prop-types";
import { Navigate, useLocation } from "react-router-dom";

import Footer from "./footer";
import { OuterWrapper } from "../theme";
import { AuthContext } from "./authContextProvider";

const SignInLayout = ({ children }) => {
  const { state } = useLocation();
  const { user } = React.useContext(AuthContext);

  if (user) return <Navigate to={state ? state.from : "/clients"} />;

  return (
    <OuterWrapper>
      <div className="body">
        <div className="login">
          <div className="box">
            {children}
            <Footer />
          </div>
        </div>
      </div>
    </OuterWrapper>
  );
};

export default SignInLayout;

SignInLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
