import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";

const primaryFont = document.createElement("link");
primaryFont.rel = "stylesheet";
primaryFont.href =
	"https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;600;700&display=swap";
document.head.appendChild(primaryFont);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>
);
