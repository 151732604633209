import React from "react";
import DevicesOtherIcon from "@mui/icons-material/DevicesOther";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Grid } from "@mui/material";
import QRCode from "react-qr-code";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Tooltip } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SettingsIcon from '@mui/icons-material/Settings';

import PageLayout from "../components/pageLayout";
import UseButton from "../components/useButton";
import UseCheckbox from "../components/useCheckbox";
import UseTable from "../components/useTable";
import UsePopOver from "../components/usePopOver";
import UseEmpty from "../components/useEmpty";
import UseLoading from "../components/useLoading";
import { useSizeWithChild } from "../components/useWindowSize";
import { QrCodeStyled, WrapperStyled } from "../theme";
import { get } from "../serverRequest";
import { log } from "../logger";
import { updateObject, dynamicSort } from "../components/utility";
import UseAutocomplete from "../components/useAutocomplete";
import UseDialog from "../components/useDialog";
import UseTooltip from "../components/UseTooltip";
import JSONEditor from "../components/jsonEditor";

const Devices = () => {
	const wrapDivRef = React.useRef()
	const qrCodeRef = React.useRef()
	const JSONEditorRef = React.useRef()
	const wrapDiv = useSizeWithChild(wrapDivRef)

	const [isOpen, setIsOpen] = React.useState(false)
	const filterOpenClickHandler = () => setIsOpen((prev) => !prev)

	let tableHeight, tableWidth
	if (wrapDiv.height && wrapDiv.width) {
		tableHeight = wrapDiv.height - 20
		tableWidth = wrapDiv.width
	}
	const [data, setData] = React.useState({
		companies: {
			list: [],
			selected: undefined,
			reload: true,
		},
		locations: {
			selected: undefined,
		},
		devices: {
			list: [],
			loading: true,
			reload: true,
			selected: "",
		},
		filter: {
			clicked: false,
			offline: null,
			outOfService: null,
		},
		clickedToCopy: false,
	});

	// Get companies list
	React.useEffect(() => {
		let isMounted = true
		if (data.companies.reload) {
			get("companies/list")
				.then((response) => {
					log("Success: get-companies/list--devices.js", response, "log");
					if (isMounted) {
						setData((prev) =>
							updateObject(prev, {
								companies: updateObject(prev.companies, {
									list: response.sort(dynamicSort('name')),
									reload: false,
								}),
							})
						);
					}
				})
				.catch((status) => {
					log("Error: get-companies/list--devices.js", status);
					setData((prev) =>
						updateObject(prev, {
							companies: updateObject(prev.companies, {
								reload: false,
							}),
						})
					);
				});
		}
		return () => (isMounted = false);
	}, [data.companies.reload]);

	// Devices List
	React.useEffect(() => {
		let isMounted = true;
		if (data.devices.reload && data.devices.loading) {
			let params = {};

			if (data.companies.selected)
				params.companyId = data.companies.selected.id;
			if (data.locations.selected)
				params.locationId = data.locations.selected.id;
			if (data.filter.offline) params.online = !data.filter.offline;
			if (data.filter.outOfService)
				params.outOfService = data.filter.outOfService;

			get("devices/list", params)
				.then((response) => {
					log("Success: get-devices/list--devices.js", response, "log");
					if (isMounted) {
						setData((prev) =>
							updateObject(prev, {
								devices: updateObject(prev.devices, {
									list: response,
									reload: false,
									loading: false,
								}),
								filter: updateObject(prev.filter, {
									clicked: false,
								}),
							})
						);
					}
				})
				.catch((status) => {
					log("Error: get-devices/list--devices.js", status);
					setData((prev) =>
						updateObject(prev, {
							devices: updateObject(prev.devices, {
								reload: false,
								loading: false,
							}),
							filter: updateObject(prev.filter, {
								clicked: false,
							}),
						})
					);
				});
		}
		return () => (isMounted = false);
	}, [
		data.devices.reload,
		data.devices.loading,
		data.companies.selected,
		data.filter,
		data.locations.selected,
	]);

	const changeHandler = (current, type) => {
		setData((prev) => {
			let obj = updateObject(prev, {
				[type]: updateObject(prev[type], {
					selected: current,
				}),
			});
			if (type === "companies") obj.locations.selected = undefined;
			return obj;
		});
	};

	const filterClickedHandler = () => {
		setData((prev) =>
			updateObject(prev, {
				devices: updateObject(prev.devices, {
					loading: true,
					reload: true,
				}),
				filter: updateObject(prev.filter, {
					clicked: true,
				}),
			})
		);
		setIsOpen(false)
	};

	const changeCheckHandler = (value, type) => {
		setData((prev) =>
			updateObject(prev, {
				filter: updateObject(prev.filter, {
					[type]: value,
				}),
			})
		);
	};

	const columns = [
		// {
		// 	id: "id",
		// 	label: "Id",
		// 	searchBy: false,
		// 	orderBy: false,
		// 	align: "left",
		// },
		{
			id: "location.company.name",
			label: "Empresa",
			searchBy: false,
			orderBy: true,
			align: "left",
		},
		{
			id: "location.name",
			label: "Localização",
			align: "left",
			searchBy: false,
			orderBy: true,
		},
		{
			id: "name",
			label: "Dispositivo",
			searchBy: false,
			orderBy: true,
			align: "left",
		},
		{
			id: "online",
			label: "Ligado",
			format: (value) => (
				<span className={value ? "" : "alert"}>
					{value ? "Ligado" : "Desligado"}
				</span>
			),
			searchBy: false,
			orderBy: true,
			align: "left",
		},
		{
			id: "outOfService",
			label: "Fora de serviço",
			format: (value) => (
				<span className={value ? "alert" : ""}>
					{value ? "Fora de serviço" : "Em serviço"}
				</span>
			),
			searchBy: false,
			orderBy: true,
			align: "left",
		},
		{
		  id: "action",
		  label: "Action",
		  actions: [
		    {
		      id: "edit",
		      display: "icon", //icon, text, both
		      text: "Setting",
		      icon: <SettingsIcon />,
		      click: (data) => JSONEditorRef.current.Open(),
		    },
		  ],
		},
	];

	const rowClickHandler = (row) => {
		setData((prev) =>
			updateObject(prev, {
				devices: updateObject(prev.devices, {
					selected: row.id,
				}),
				clickedToCopy: false,
			})
		);
		qrCodeRef.current.Open();
	};

	return (
		<>
			<WrapperStyled ref={wrapDivRef}>
				<h2>
					<DevicesOtherIcon /> Dispositivos
					<span style={{marginLeft: 'auto'}}>
					<UseTooltip title='Filtros' placement='left'>
					<span onClick={filterOpenClickHandler} className={isOpen ? "isOpen" : ""}>
						<FilterAltIcon />
					</span>
					</UseTooltip>
					<UsePopOver
					isOpen={isOpen}
					toggler={setIsOpen}
					top={25}
					>
						<Grid container spacing={3}>
							{data.companies.list.length > 0 && (
								<Grid item xs={12}>
									<UseAutocomplete
										label="Empresas"
										options={data.companies.list}
										selected={data.companies.selected}
										onChange={(data) => changeHandler(data, "companies")}
										multiple={false}
										searchable={true}
										className="noMargin"
									/>
								</Grid>
							)}
							{data.companies.selected &&
								data.companies.selected.locations.length > 0 && (
									<Grid item xs={12}>
										<UseAutocomplete
											label="Localizações"
											options={data.companies.selected.locations.sort(dynamicSort('name'))}
											selected={data.locations.selected}
											onChange={(data) => changeHandler(data, "locations")}
											multiple={false}
											searchable={true}
											className="noMargin"
										/>
									</Grid>
								)}
							<Grid item xs={12}>
								<UseCheckbox
									label="Desligado"
									value={data.filter.offline}
									onChange={(e) =>
										changeCheckHandler(e.target.checked, "offline")
									}
								/>
							</Grid>
							<Grid item xs={12}>
								<UseCheckbox
									label="Fora de serviço"
									value={data.filter.outOfService}
									onChange={(e) =>
										changeCheckHandler(e.target.checked, "outOfService")
									}
								/>
							</Grid>
							<Grid item xs={12}>
								<UseButton
									type="button"
									skin={data.filter.clicked ? "disable" : "primary"}
									clicked={filterClickedHandler}
									display="full"
									value="Aplicar filtro"
									loading={data.filter.clicked}
								/>
							</Grid>
						</Grid>
					</UsePopOver>
					</span>
				</h2>

				{data.devices.list.length === 0 &&
					data.devices.loading &&
					tableHeight &&
					tableWidth && (
						<UseLoading width={`${tableWidth}px`} height={`${tableHeight}px`} />
					)}

				{data.devices.list.length === 0 &&
					!data.devices.loading &&
					tableHeight &&
					tableWidth && (
						<UseEmpty
							width={`${tableWidth}px`}
							height={`${tableHeight}px`}
							text="Não encontramos nenhum dispositivo com estas configurações de filtro."
						/>
					)}

				{data.devices.list.length > 0 &&
					!data.devices.loading &&
					tableHeight &&
					tableWidth && (
						<UseTable
							defaultOrderBy="location.company.name"
							tableWidth={tableWidth}
							tableHeight={tableHeight}
							data={data.devices.list}
							columns={columns}
							className="marginTop"
							columnsHeight={50.52}
							rowClick={rowClickHandler}
						/>
					)}
			</WrapperStyled>
			<UseDialog ref={JSONEditorRef} title="Configuração" size="sm"><JSONEditor /></UseDialog>
			<UseDialog ref={qrCodeRef} title="Qr Code" size="xs">
				{data.devices.selected && (
					<QrCodeStyled>
						<QRCode value={data.devices.selected} />

						<Tooltip
							placement="bottom"
							title={data.clickedToCopy ? "Copied" : "Click to copy"}
						>
							<div className="clickToCopy">
								<CopyToClipboard
									text={data.devices.selected}
									onCopy={() => {
										setData((prev) =>
											updateObject(prev, {
												clickedToCopy: true,
											})
										);
									}}
								>
									<div className="clickToCopy">
										<span>{data.devices.selected}</span>
										<ContentCopyIcon />
									</div>
								</CopyToClipboard>
							</div>
						</Tooltip>
					</QrCodeStyled>
				)}
			</UseDialog>
		</>
	);
};

export default PageLayout(Devices);
