import React from "react";
import Styled from "styled-components";
import { useNavigate } from "react-router-dom";

import Logo from "./logo";
import Menu from "./menu";
import UserInformation from "./userInformation";
import useWindowSize from "./useWindowSize";
import useBlanket from "./useBlanket";

const Header = () => {
	const navigate = useNavigate();

	const { width, height } = useWindowSize();
	let isCollapse = width < 991;

	const [isOpen, setIsOpen] = React.useState(false);
	const openMenuHandler = React.useCallback(
		() => setIsOpen((prev) => !prev),
		[]
	);

	useBlanket(isOpen, openMenuHandler, 17);
	return (
		<HeaderStyled className="header" isCollapse={isCollapse}>
			{isCollapse && (
				<SandwichMenu isOpen={isOpen} onClick={openMenuHandler}>
					<span></span>
					<span></span>
					<span></span>
				</SandwichMenu>
			)}
			<Logo onClick={() => navigate("/dashboard")} />
			{height && (
				<Menu isCollapse={isCollapse} isOpen={isOpen} height={height} />
			)}
			<UserInformation /> 
		</HeaderStyled>
	);
};

export default React.memo(Header);

const HeaderStyled = Styled.div`
  ${(props) => {
		let theme = props.theme;
		return `
        border-bottom:1px solid rgba(${theme.black},0.063);
        >h1{
          display:flex;
          justify-content:center;
          align-items:center;
          cursor:pointer;


          >img{
            height:48px;
            width:auto;
            margin-right:${theme.gMD};
          }
          >div{
            display:flex;
            flex-direction:column;
            justify-content:center;
            padding-top:4px;
            text-align:center;

            margin-top: 8px;

            ${theme.fontPrimary};
            color:rgba(${theme.secondary},1);

            >p{
              ${theme.fXXXL};
              >span{
                color:rgba(${theme.primary},1);
              }
            }
            >span{
              ${theme.fSM};
              text-transform: uppercase;
              ${theme.fontMono};
              margin-top:7px;
            }
          }
        }

      .userInformation{
        >span{
          background:rgba(${theme.primary},1);
          padding:${theme.gLG};
          border-radius:5px;
          color:rgb(${theme.white});
          border:1px solid rgba(${theme.primary},1);
          margin-left: 10px;
          cursor:pointer;

          &.isOpen{
            background:rgba(${theme.white},1)!important;
            border-radius:5px 5px 0 0;
            color:rgb(${theme.black});
            border:1px solid rgba(${theme.black},0.0625);

            ::after {
              content: "";
              height: 3px;
              width: calc(100% - 9px);
              background: rgb(${theme.white});
              position: absolute;
              bottom: -13px;
              z-index: 21;
              right:1px;
              display: block;
          }
          }
        }
        }

        ${
					props.isCollapse &&
					`
            justify-content:center;

            >h1{
              margin-left:auto;
              margin-right:auto;
            }
          
          `
				}
    `;
	}}
`;

const SandwichMenu = Styled.div`
  ${(props) => {
		let theme = props.theme;
		return `
    flex-direction: column;
    width: 30px;
    height: 30px;
    display:flex;
    justify-content: space-around;
    cursor: pointer;
    z-index: 14;
    margin-right: ${theme.gSM};
      >span{
        height:2px;
        background:rgb(${theme.primary})!important;
        transition: 0.2s;
      }
      >span:nth-child(2){
        width:25px;
      }
      ${
				props.isOpen &&
				`
        z-index: 19;
        display: flex;
        > span:first-child {
          display: none;
        }
        > span {
          transform: rotate(-45deg) translateY(10px);
          width: 30px !important;
          background: rgb(${theme.white})!important;
          margin-left: -${theme.gMD};
        }
        > span:last-child {
          transform: rotate(45deg) translateY(-10px);
        }
      `
			}
    `;
	}};
`;
