import React from "react";
import Styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import BusinessIcon from "@mui/icons-material/Business";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import DevicesIcon from "@mui/icons-material/Devices";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Avatar, Tooltip } from "@mui/material";

import PageLayout from "../components/pageLayout";
import ManagementTabs from "../components/managementTabs";
import UseDialog from "../components/useDialog";
import CompanyPopup from "../components/companyPopup";
import LocationPopup from "../components/locationPopup";
import DevicePopup from "../components/devicePopup";
import ProductPopup from "../components/productPopup";
import PricePopup from "../components/pricePopup";
import UseTable from "../components/useTable";
import { useDivSize } from "../components/useWindowSize";
import { getErrorMessageWidthStatus, updateObject } from "../components/utility";
import { get } from "../serverRequest";
import { log } from "../logger";
import { AuthContext } from "../components/authContextProvider";

const tabs = {
	companies: {
		title: "Empresas",
		icon: <BusinessIcon />,
	},
	locations: {
		title: "Localizações",
		icon: <MyLocationIcon />,
	},
	devices: {
		title: "Dispositivos",
		icon: <DevicesIcon />,
	},
	products: {
		title: "Produtos",
		icon: <ShoppingCartIcon />,
	},
	prices: {
		title: "Preços",
		icon: <EuroSymbolIcon />,
	},
};

const Managements = () => {
	const wrapDivRef = React.useRef();
	const currentPopRef = React.useRef();

	const { snackbar } = React.useContext(AuthContext);

	const wrapDiv = useDivSize(wrapDivRef);
	let divHeight, divWidth;
	if (wrapDiv.height && wrapDiv.width) {
		divHeight = wrapDiv.height;
		divWidth = wrapDiv.width;
	}
	let tableWidth = divWidth - 270;
	if (divWidth < 769) tableWidth = divWidth - 30;

	const [data, setData] = React.useState({
		activeTab: "companies",
		companies: {
			isLoading: true,
			reload: true,
			list: [],
			selected: undefined,
			type: "add",
		},
		locations: {
			isLoading: true,
			reload: true,
			list: [],
			selected: undefined,
			type: "add",
		},
		devices: {
			isLoading: true,
			reload: true,
			list: [],
			selected: undefined,
			type: "add",
		},
		products: {
			isLoading: true,
			reload: true,
			list: [],
			selected: undefined,
			type: "add",
		},
		prices: {
			isLoading: true,
			reload: true,
			list: [],
			selected: undefined,
			type: "add",
		},
	});

	// Get Locations list from the server
	React.useEffect(() => {
		let isMounted = true;

		if (data.locations.isLoading && data.locations.reload) {
			get("locations/list")
				.then((response) => {
					log("Success: get-locations/list--managements.js", response, "log");
					if (isMounted) {
						setData((prev) =>
							updateObject(prev, {
								locations: updateObject(prev.locations, {
									reload: false,
									loading: false,
									list: response,
								}),
							})
						);
					}
				})
				.catch((status) => {
					log("Error: get-locations/list--managements.js", status);
					setData((prev) =>
						updateObject(prev, {
							locations: updateObject(prev.locations, {
								reload: false,
								loading: false,
							}),
						})
					);
					snackbar(getErrorMessageWidthStatus(status));
				});
		}

		return () => (isMounted = false);
	}, [data.locations.isLoading, data.locations.reload, snackbar]);

	// Get Devices list from server
	React.useEffect(() => {
		let isMounted = true;

		if (data.devices.isLoading && data.devices.reload) {
			get("devices/list")
				.then((response) => {
					log("Success: get-devices/list--managements.js", response, "log");
					if (isMounted) {
						setData((prev) =>
							updateObject(prev, {
								devices: updateObject(prev.devices, {
									reload: false,
									loading: false,
									list: response,
								}),
							})
						);
					}
				})
				.catch((status) => {
					log("Error: get-devices/list--managements.js", status);
					setData((prev) =>
						updateObject(prev, {
							devices: updateObject(prev.devices, {
								reload: false,
								loading: false,
							}),
						})
					);
					snackbar(getErrorMessageWidthStatus(status));
				});
		}

		return () => (isMounted = false);
	}, [data.devices.reload, data.devices.isLoading, snackbar]);

	// Get Companies List from server
	React.useEffect(() => {
		let isMounted = true;

		if (data.companies.isLoading && data.companies.reload) {
			get("companies/list")
				.then((response) => {
					log("Success: get-companies/list--management.js", response, "log");
					if (isMounted) {
						setData((prev) =>
							updateObject(prev, {
								companies: updateObject(prev.companies, {
									list: response,
									reload: false,
									isLoading: false,
								}),
							})
						);
					}
				})
				.catch((status) => {
					log("Error: get-companies/list--management.js", status);
					setData((prev) =>
						updateObject(prev, {
							companies: updateObject(prev.companies, {
								reload: false,
								isLoading: false,
							}),
						})
					);
					snackbar(getErrorMessageWidthStatus(status));
				});
		}

		return () => (isMounted = false);
	}, [data.companies.reload, data.companies.isLoading, snackbar]);

	const changeTabHandler = (activeTab) =>
		setData((prev) => updateObject(prev, { activeTab }));

	let currentIconElement = tabs[data.activeTab].icon;
	let currentTitleElement = tabs[data.activeTab].title;
	let popupTitle = currentTitleElement.toLocaleLowerCase();
	if (data[data.activeTab].type === "edit") {
		popupTitle = `Editar ${popupTitle}`;
	} else if (data[data.activeTab].type === "delete") {
		popupTitle = `Apagar ${popupTitle}`;
	} else {
		popupTitle = `Adicionar ${popupTitle}`;
	}

	const openPopupClickHandler = (curr, type) => {
		setData((prev) =>
			updateObject(prev, {
				[prev.activeTab]: updateObject(prev[prev.activeTab], {
					selected: curr,
					type,
				}),
			})
		);
		currentPopRef.current.Open();
	};

	const updateDataHandler = () => {
		setData((prev) =>
			updateObject(prev, {
				[prev.activeTab]: updateObject(prev[prev.activeTab], {
					isLoading: true,
					reload: true,
				}),
			})
		);
	};

	let popupElement = null;
	let tableData = [];
	let columns = [];
	let columnsHeight = 58.52;
	let defaultOrderBy = "";
	if (data.activeTab === "companies") {
		tableData = data.companies.list;
		defaultOrderBy = "name";
		columnsHeight = 73;
		columns = [
			{
				id: "logo",
				label: "",
				searchBy: false,
				orderBy: false,
				align: "left",
				minWidth: 20,
				format: (value) => {
					return <Avatar alt="logo" variant="rounded" src={value} />;
				},
				width: 20,
			},
			{
				id: "name",
				label: "Nome da empresa",
				searchBy: false,
				orderBy: true,
				align: "left",
				className: "leftBorder",
			},
			{
				id: "action",
				label: "Action",
				width: 120,
				className: "leftBorder",
				align: "center",
				searchBy: false,
				orderBy: false,
				actions: [
					{
						id: "edit",
						display: "icon", // icon, text, both
						text: "Edit",
						icon: <EditIcon />,
						click: (curr) => openPopupClickHandler(curr, "edit"),
						className: "edit",
						tooltip: "Edit",
					},
				],
			},
		];

		popupElement = (
			<CompanyPopup
				selected={data[data.activeTab].selected}
				type={data[data.activeTab].type}
				success={updateDataHandler}
			/>
		);
	} else if (data.activeTab === "locations") {
		defaultOrderBy = "name";
		tableData = data.locations.list;
		columns = [
			{
				id: "name",
				label: "Localizações",
				searchBy: false,
				orderBy: true,
				align: "left",
			},
			{
				id: "company.name",
				label: "Nome da empresa",
				searchBy: false,
				orderBy: true,
				align: "left",
			},
			{
				id: "latitude",
				label: "Latitude",
				searchBy: false,
				orderBy: true,
				align: "left",
				className: "leftBorder",
			},
			{
				id: "longitude",
				label: "Longitude",
				searchBy: false,
				orderBy: true,
				align: "left",
				className: "leftBorder",
			},
			{
				id: "action",
				label: "Action",
				width: 120,
				className: "leftBorder",
				align: "center",
				searchBy: false,
				orderBy: false,
				actions: [
					{
						id: "edit",
						display: "icon", // icon, text, both
						text: "Edit",
						icon: <EditIcon />,
						click: (curr) => openPopupClickHandler(curr, "edit"),
						className: "edit",
						tooltip: "Edit",
					},
					{
						id: "delete",
						display: "icon",
						text: "Delete",
						icon: <DeleteIcon />,
						click: (curr) => openPopupClickHandler(curr, "delete"),
						className: "delete",
						tooltip: "Delete",
					},
				],
			},
		];

		popupElement = (
			<LocationPopup
				companies={data.companies.list}
				selected={data[data.activeTab].selected}
				type={data[data.activeTab].type}
				success={updateDataHandler}
			/>
		);
	} else if (data.activeTab === "devices") {
		defaultOrderBy = "name";
		tableData = data.devices.list;
		columns = [
			{
				id: "name",
				label: "Dispositivos",
				searchBy: false,
				orderBy: true,
				align: "left",
				className: "leftBorder",
			},
			{
				id: "location.name",
				label: "Localizações",
				searchBy: false,
				orderBy: true,
				align: "left",
				className: "leftBorder",
			},
			{
				id: "location.company.name",
				label: "Nome da empresa",
				searchBy: false,
				orderBy: true,
				align: "left",
				className: "leftBorder",
			},
			{
				id: "online",
				label: "Online",
				searchBy: false,
				orderBy: true,
				align: "left",
				className: "leftBorder",
				format: (value) => (
					<span className={value ? "" : "alert"}>
						{value ? "Ligado" : "Desligado"}
					</span>
				),
			},
			{
				id: "outOfService",
				label: "Serviço",
				align: "center",
				searchBy: false,
				orderBy: true,
				format: (value) => (
					<span className={value ? "alert" : ""}>
						{value ? "Fora de serviço" : "Em serviço"}
					</span>
				),
				className: "leftBorder",
			},
			{
				id: "action",
				label: "Action",
				width: 120,
				className: "leftBorder",
				align: "center",
				searchBy: false,
				orderBy: false,
				actions: [
					{
						id: "edit",
						display: "icon", // icon, text, both
						text: "Edit",
						icon: <EditIcon />,
						click: (curr) => openPopupClickHandler(curr, "edit"),
						className: "edit",
						tooltip: "Edit",
					},
					{
						id: "delete",
						display: "icon",
						text: "Delete",
						icon: <DeleteIcon />,
						click: (curr) => openPopupClickHandler(curr, "delete"),
						className: "delete",
						tooltip: "Delete",
					},
				],
			},
		];
		popupElement = (
			<DevicePopup
				locations={data.locations.list}
				selected={data[data.activeTab].selected}
				type={data[data.activeTab].type}
				success={updateDataHandler}
			/>
		);
	} else if (data.activeTab === "products") {
		popupElement = (
			<ProductPopup
				selected={data[data.activeTab].selected}
				type={data[data.activeTab].type}
				success={updateDataHandler}
			/>
		);
	} else if (data.activeTab === "prices") {
		popupElement = (
			<PricePopup
				products={data.products.list}
				devices={data.devices.list}
				selected={data[data.activeTab].selected}
				type={data[data.activeTab].type}
				success={updateDataHandler}
			/>
		);
	}

	let tableMemo = React.useMemo(() => {
		if (columns.length > 0 && tableData.length > 0) {
			return (
				<UseTable
					tableWidth={tableWidth}
					tableHeight={divHeight - 80}
					data={tableData}
					columns={columns}
					className="marginTop"
					columnsHeight={columnsHeight}
					defaultOrderBy={defaultOrderBy}
				/>
			);
		}
	}, [
		tableWidth,
		divHeight,
		tableData,
		columns,
		columnsHeight,
		defaultOrderBy,
	]);

	return (
		<ManagementStyled ref={wrapDivRef} width={divWidth} height={divHeight}>
			<div>
				<ManagementTabs
					tabs={tabs}
					current={data.activeTab}
					switchTab={changeTabHandler}
				/>
			</div>
			<div>
				<h2>
					{currentIconElement}
					{currentTitleElement}
					<span onClick={() => openPopupClickHandler(undefined, "add")}>
						<Tooltip placement="left" title={popupTitle} arrow>
							<AddIcon />
						</Tooltip>
					</span>
				</h2>
				{tableMemo}
				<UseDialog
					ref={currentPopRef}
					icon={currentIconElement}
					title={popupTitle}
					size="sm"
					type={data[data.activeTab].type}
				>
					{popupElement}
				</UseDialog>
			</div>
		</ManagementStyled>
	);
};

export default PageLayout(Managements);

export const ManagementStyled = Styled.div`
    ${(props) => {
			let theme = props.theme;
			let leftWidth = 250;
			let rightWidth = "100%";
			if (props.width) rightWidth = props.width - (leftWidth + 20) + "px";
			return `
            display:flex;
            height: 100%;
            justify-content: space-between;
            >div{
                height:${props.height}px;
            }
            >div:first-child{
                background:rgba(${theme.black},0.0225);
                width:${leftWidth}px;
            }
            >div:last-child{
                width:${rightWidth};
                padding:${theme.gLG};
                height:${props.height - 30}px;

                >h2{
                  display:flex;
                  align-items:center;
                  font-size:16px;
                  height:30px;

                  >svg{
                    margin-right:${theme.gSM};
                  }

                  >span{
                    padding:0 ${theme.gMD};
                    margin-left:auto;
                    cursor:pointer;
                    height:100%;
                    :hover{
                      color:rgba(${theme.primary},1);
                    }
                  }
                }
            }

            ${theme.mMX_SM}{
                flex-direction: column;
                justify-content: flex-start;
                >div:first-child{
                  width:100%!important;
                  height:unset!important;
              }
              >div:last-child{
                width:calc(100% - 30px)!important;
                height:unset!important;
            }
            }
        `;
		}}
`;
