import React from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";

const UseSelect = ({ config }) => {
	return (
		<SelectStyled
			className={`${config.className} input ${
				!config.valid && config.touched ? "error" : ""
			}`}
		>
			<span>
				<span className="up"></span>
				<span className="down"></span>
			</span>
			<select
				name={config.element.name}
				className="focused"
				onChange={config.element.onChange}
				defaultValue={config.value}
			>
				{config.element.options &&
					config.element.options.length > 0 &&
					config.element.options.map((option, index) => (
						<option value={option.value} key={index}>
							{option.label}
						</option>
					))}
			</select>
			<label htmlFor={config.element.name} className="placeholder">
				{config.label}
			</label>
		</SelectStyled>
	);
};

export default React.memo(UseSelect);

UseSelect.propTypes = {
	config: PropTypes.shape({
		label: PropTypes.string.isRequired,
		value: PropTypes.string,
		element: PropTypes.shape({
			name: PropTypes.string.isRequired,
		}).isRequired,
		className: PropTypes.string,
	}),
};

const SelectStyled = Styled.div`
    ${(props) => {
			let theme = props.theme;
			return `
            display:block;
            position: relative;
            margin-bottom:5px;

            >select{
                width:calc(100% - 2px);
                border: none;
                -webkit-appearance: none;
                -ms-accelerator: none;
                -moz-appearance: none;
                appearance: none;
                resize: none;
                outline: none;
                border-radius:4px;
                padding:${theme.gMD};
                min-height:50px;
                display:flex;
                justify-content:center;
                align-items:center;

                border:1px solid rgba(${theme.black},0.23);
            }

            >span{
                width:40px;
                height:100%;
                background:rgba(239,239,239,1);
                position: absolute;
                top:0;
                right:0;
                border-radius:0 5px 5px 0;
                display:flex;
                justify-content:center;
                align-items:center;
                flex-direction:column;
                pointer-events: none;

                >span.up{
                    width: 0; 
                    height: 0; 
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    
                    border-bottom: 5px solid #000;
                    margin-bottom:1px;
                    pointer-events: none;
                }
                >span.down{
                    width: 0; 
                    height: 0; 
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    
                    border-top: 5px solid #000;
                    pointer-events: none;
                }
            }
            
            .placeholder {
                pointer-events: none;
                position: absolute;
                top: calc(36px);
                left: 11px;

                -webkit-transform: translateY(-100%);
                transform: translateY(-100%);

                transition: top .2s ease,font-size .2s ease,color .2s ease;

                height:20px;
                background:rgb(${theme.white});
                width:calc(100% - 42px);
                color:rgba(${theme.black},0.4);

                ::after {
                    height:1px;
                    background:rgb(${theme.white});
                    content: "";
                    left: -5px;
                    position: absolute;
                    top: calc(50%);
                    width: 100%;
                    z-index: -1;
                    border:0;
                    padding:0 5px;
                }

            }
            .focused+.placeholder, select.focused+.placeholder, select:focus+.placeholder {
                top: 10px!important;
                width:auto;
                background:transparent;
                font-size:14px;
                color:rgba(${theme.black},0.4);
            }


        `;
		}}
`;
