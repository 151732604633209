import React from "react";
import PropTypes from "prop-types";

const UseEmpty = ({ width = "100%", height = "100%", text }) => {
	return (
		<div
			style={{
				width: width,
				height: height,
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			{text}
		</div>
	);
};

export default UseEmpty;

UseEmpty.propTypes = {
	width: PropTypes.string,
	height: PropTypes.string,
	text: PropTypes.string.isRequired,
};
