import React from "react";

const usePositions = (currentRef) => {
  const [position, setPosition] = React.useState({
    top: undefined,
    left: undefined,
    right: undefined,
    height: undefined,
    width: undefined,
  });

  const ref = React.useCallback((node) => {
    if (node) {
      setPosition({
        top: node.offsetTop,
        left: node.offsetLeft,
        right: node.offsetRight,
        height: node.offsetHeight,
        width: node.clientWidth,
      });
    }
  }, []);

  React.useLayoutEffect(() => {
    const updatePosition = () =>
      setPosition({
        top: currentRef.current.offsetTop,
        left: currentRef.current.offsetLeft,
        right: currentRef.current.offsetRight,
        height: currentRef.current.offsetHeight,
        width: currentRef.current.offsetWidth,
      });

    window.addEventListener("resize", updatePosition);
    updatePosition();
    return () => window.removeEventListener("resize", updatePosition);
  }, []);

  if (currentRef) return position;
  return [position, ref];
};
export default usePositions;
