import React from "react";
import LockIcon from "@mui/icons-material/Lock";
import LoginIcon from "@mui/icons-material/Login";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

import Logo from "../components/logo";
import SignInLayout from "../components/signInLayout";
import UseButton from "../components/useButton";
import UseInput from "../components/useInput";
import { AuthContext } from "../components/authContextProvider";
import { signIn } from "../firebase";
import { SignInStyled } from "../theme";
import {
  getErrorMessageWidthStatus,
  selectNextOnEnter,
  updateInputChange,
  updateObject,
} from "../components/utility";
import { log } from "../logger";

const SignIn = () => {
  const emailRef = React.useRef();
  const passwordRef = React.useRef();
  const { setAuthLoading, snackbar } = React.useContext(AuthContext);

  const [sighInLoader, setSighInLoader] = React.useState(false);
  const [formInputs, setFormInputs] = React.useState({
    email: {
      label: "Email",
      icons: {
        icon: <AlternateEmailIcon />,
        align: "left", // left or right
      },
      validation: {
        required: true,
      },
      inputRef: emailRef,
      valid: true,
      touched: false,
      value: "",
      element: {
        type: "email",
        placeholder: "Seu email",
        autoComplete: "Off",
        onKeyDown: (e) => selectNextOnEnter(e, passwordRef),
      },
    },
    password: {
      label: "Palavra-passe",
      icons: {
        icon: <LockIcon />,
        align: "left", // left or right
      },
      validation: {
        required: true,
        min: 6,
      },
      inputRef: passwordRef,
      valid: true,
      touched: false,
      value: "",
      element: {
        type: "password",
        placeholder: "Sua Palavra-passe",
        autoComplete: "Off",
      },
    },
    submit: {
      label: "Entrar",
      icons: {
        loading: true,
        icon: <LoginIcon />,
      },
      validation: {
        required: true,
      },
      inputRef: null,
      valid: true,
      element: {
        type: "button",
        display: "full",
      },
    },
  });

  const formElement = [];
  for (let key in formInputs) {
    formElement.push({
      id: key,
      config: formInputs[key],
    });
  }

  const inputChangeHandler = (e, key) =>
    setFormInputs((prev) => updateInputChange(prev, e.target.value, key));

  const signInClicked = (e) => {
    e.preventDefault();

    setSighInLoader(true);
    signIn(emailRef.current.value, passwordRef.current.value)
      .then(() => {
        setAuthLoading(true);
      })
      .catch((status) => {
        log("Error: signIn--signIn.js", status);
        setAuthLoading(false);
        setSighInLoader(false);
        snackbar(getErrorMessageWidthStatus(status));
      });
  };
  return (
    <SignInLayout>
      <SignInStyled>
        <Logo />
        <div className="box">
          <h2>Entrar</h2>
          <form onSubmit={signInClicked} autoComplete="off" method="post">
            {formElement.map((el) => {
              let skinEl = sighInLoader
                ? "disable"
                : el.config.valid
                ? "primary"
                : "disable";
              if (el.id === "submit") {
                return (
                  <UseButton
                    key={el.id}
                    type={el.config.element.type}
                    skin={skinEl}
                    clicked={signInClicked}
                    display={el.config.element.display}
                    value={el.config.label}
                    icons={el.config.icons}
                    loading={sighInLoader}
                  />
                );
              } else {
                return (
                  <UseInput
                    key={el.id}
                    config={updateObject(el.config, {
                      element: updateObject(el.config.element, {
                        name: el.id,
                        onChange: (e) => inputChangeHandler(e, el.id),
                      }),
                    })}
                    ref={el.config.inputRef}
                  />
                );
              }
            })}
          </form>
        </div>
      </SignInStyled>
    </SignInLayout>
  );
};

export default SignIn;
