import React from "react";
import Styled from "styled-components";
import PropTypes from "prop-types";

const ManagementTabs = ({ tabs, current, switchTab }) => {
  return (
    <TabsStyled>
      {Object.keys(tabs).map((item, index) => (
        <li
          className={item === current ? "active" : ""}
          onClick={() => switchTab(item)}
          key={index}
        >
          {tabs[item].icon}
          {tabs[item].title}
        </li>
      ))}
    </TabsStyled>
  );
};

export default ManagementTabs;

ManagementTabs.propTypes = {
  tabs: PropTypes.object.isRequired,
  current: PropTypes.string.isRequired,
  switchTab: PropTypes.func.isRequired,
};

export const TabsStyled = Styled.ul`
    ${(props) => {
      let theme = props.theme;
      return `
            display:flex;
            flex-direction: column;
            >li{
                padding:${theme.gLG};
                display:flex;
                align-items:center;
                cursor:pointer;
                >svg{
                    margin-right:${theme.gSM};
                }
                :hover{
                    background:rgba(${theme.white},1);
                }
            }
            >li.active{
                background:rgba(${theme.white},1);
                color:rgba(${theme.primary},1);
            }

            ${theme.mMX_SM}{
                flex-direction: row;
            }

            ${theme.mMX_XSM}{
                flex-direction: column;
            }
        `;
    }}
`;
