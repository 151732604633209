import React from "react";
import LockIcon from "@mui/icons-material/Lock";

import UseButton from "./useButton";
import UseInput from "./useInput";
import { getErrorMessageWidthStatus, updateInputChange, updateObject } from "./utility";
import { changePassword } from "../firebase";
import { AuthContext } from "./authContextProvider";

const ChangePassword = () => {
  const passwordRef = React.useRef();
  const newPasswordRef = React.useRef();

  const { user, snackbar } = React.useContext(AuthContext);

  const [signingIn, setChangingPassword] = React.useState(false);
  const [formInputs, setFormInputs] = React.useState({
    password: {
      label: "Palavra-passe",
      icons: {
        icon: <LockIcon />,
        align: "left",
      },
      validation: {
        required: true,
        min: 6,
        notEqual: "newPassword",
      },
      inputRef: passwordRef,
      valid: false,
      touched: false,
      value: "",
      element: {
        type: "password",
        placeholder: "Sua Palavra-passe",
        autoComplete: "off",
      },
    },
    newPassword: {
      label: "Nova palavra-passe",
      icons: {
        icon: <LockIcon />,
        align: "left", // left or right
      },
      validation: {
        required: true,
        min: 6,
        notEqual: "password",
        equal: "newPassword",
      },
      inputRef: newPasswordRef,
      valid: false,
      touched: false,
      value: "",
      element: {
        type: "password",
        placeholder: "Sua Nova palavra-passe",
        autoComplete: "off",
      },
    },
    confirmPassword: {
      label: "Repita a palavra-passe",
      icons: {
        icon: <LockIcon />,
        align: "left", // left or right
      },
      validation: {
        required: true,
        min: 6,
        equal: "newPassword",
      },
      valid: false,
      touched: false,
      value: "",
      element: {
        type: "password",
        placeholder: "Repita a palavra-passe",
        autoComplete: "off",
      },
    },
    submit: {
      label: "Alterar",
      icons: {
        loading: true,
      },
      validation: {
        required: true,
      },
      inputRef: null,
      valid: false,
      element: {
        type: "button",
        display: "full",
      },
    },
  });

  const formElement = [];
  for (let key in formInputs) {
    formElement.push({
      id: key,
      config: formInputs[key],
    });
  }

  const inputChangeHandler = (e, key) =>
    setFormInputs((prev) => updateInputChange(prev, e.target.value, key));

  const changePasswordClicked = (e) => {
    e.preventDefault();
    setChangingPassword(true);

    changePassword(
      user.email,
      passwordRef.current.value,
      newPasswordRef.current.value
    )
      .then(() =>
        snackbar(
          "Palavra-passe alterada com sucesso. Por favor, faça sair e volte a entrar com nova palavra-passe?",
          "success",
          "logout"
        )
      )
      .catch((status) => {
        setChangingPassword(false);
        snackbar(getErrorMessageWidthStatus(status));
      });
  };
  return (
    <form onSubmit={changePasswordClicked} autoComplete="off">
      {formElement.map((el) => {
        let skinEl = signingIn
          ? "disable"
          : el.config.valid
          ? "primary"
          : "disable";
        if (el.id === "submit") {
          return (
            <UseButton
              key={el.id}
              type={el.config.element.type}
              skin={skinEl}
              clicked={changePasswordClicked}
              display={el.config.element.display}
              value={el.config.label}
              icons={el.config.icons}
              loading={signingIn}
            />
          );
        } else {
          return (
            <UseInput
              key={el.id}
              config={updateObject(el.config, {
                element: updateObject(el.config.element, {
                  name: el.id,
                  onChange: (e) => inputChangeHandler(e, el.id),
                }),
              })}
              ref={el.config.inputRef}
            />
          );
        }
      })}
    </form>
  );
};

export default ChangePassword;
