import React from "react";
import PropTypes from "prop-types";

import UseButton from "./useButton";
import UseInput from "./useInput";
import UseAutocomplete from "./useAutocomplete";
import { updateInputChangeSingle, updateObject, typePropsValidator } from "./utility";

const PricePopup = ({ products, devices, selected, type, success }) => {
	const [price, setPrice] = React.useState({
		amount: {
			label: "Montante",
			validation: {
				required: true,
				minVal: 0,
			},
			valid: false,
			touched: false,
			value: "",
			element: {
				name: "amount",
				type: "number",
				placeholder: "Montante",
				autoComplete: "Off",
				min: "0",
				onChange: (e) => inputChangeHandler(e, "amount"),
			},
		},
		pulses: {
			label: "Pulses",
			validation: {
				required: true,
				minVal: 0,
			},
			valid: false,
			touched: false,
			value: "",
			element: {
				name: "pulses",
				type: "number",
				placeholder: "Pulses",
				autoComplete: "Off",
				min: "0",
				onChange: (e) => inputChangeHandler(e, "pulses"),
			},
		},
		productId: {
			label: "Produto",
			validation: {
				required: true,
				type: "obj",
			},
			valid: true,
			touched: false,
			value: null,
			element: {
				multiple: false,
				searchable: true,
				onChange: (e) => inputChangeHandler(e, "productId"),
				className: "noMargin marginBottom",
			},
		},
		deviceId: {
			label: "Dispositivo",
			validation: {
				required: true,
				type: "obj",
			},
			valid: false,
			touched: false,
			value: null,
			element: {
				multiple: false,
				searchable: true,
				onChange: (e) => inputChangeHandler(e, "deviceId"),
				className: "noMargin marginBottom",
			},
		},
		submit: false,
	});

	// update if popup is for edit
	React.useEffect(() => {
		if (type === "edit" && selected)
			setPrice((prev) => {
				let obj = updateObject(prev, {
					amount: updateObject(prev.amount, {
						value: selected.amount,
						valid: true,
					}),
					pulses: updateObject(prev.pulses, {
						value: selected.pulses,
						valid: true,
					}),
					productId: updateObject(prev.productId, {
						url: selected.productId,
						valid: true,
					}),
					deviceId: updateObject(prev.deviceId, {
						url: selected.deviceId,
						valid: true,
					}),
				});

				delete obj.deviceId;
				delete obj.productId;

				return obj;
			});
	}, [selected, type]);

	const inputChangeHandler = (e, key) =>
		setPrice((prev) =>
			updateInputChangeSingle(
				prev,
				key === "deviceId" || key === "productId" ? e : e.target.value,
				key
			)
		);

	const submitClickHandler = () => {
		let toSend = {
			amount: price.amount.value,
			pulses: price.pulses.value,
			productId: price.productId.value.id,
			deviceId: price.deviceId.value.id,
		};
		if (type === "add") {
			// add price handler
		} else if (type === "edit") {
			// edit price handler
		} else {
			// delete price handler
			let id = selected.id;
		}
	};

	let buttonText = null;
	let buttonSkin = null;
	let bodyElement = (
		<div className="body">
			<UseInput config={price.amount} />
			<UseInput config={price.pulses} />
			{type !== "edit" && products.length > 0 && (
				<UseAutocomplete
					label={price.productId.label}
					options={products}
					selected={price.productId.value}
					onChange={price.productId.element.onChange}
					multiple={price.productId.element.multiple}
					searchable={price.productId.element.searchable}
					className={price.productId.element.className}
				/>
			)}
			{type !== "edit" && devices.length > 0 && (
				<UseAutocomplete
					label={price.deviceId.label}
					options={devices}
					selected={price.deviceId.value}
					onChange={price.deviceId.element.onChange}
					multiple={price.deviceId.element.multiple}
					searchable={price.deviceId.element.searchable}
					className={price.deviceId.element.className}
				/>
			)}
		</div>
	);
	if (type === "edit") {
		buttonText = `Guardar`;
		buttonSkin = "secondary";
	} else if (type === "delete") {
		buttonText = `Apagar`;
		buttonSkin = "danger";
		bodyElement = (
			<div className="body">
				Tem a certeza que quer eliminar {selected.name}?
			</div>
		);
	} else {
		buttonText = `Adicionar`;
		buttonSkin = "primary";
	}
	if (!price.submit && type !== "delete") buttonSkin = "disable";

	return (
		<div>
			{bodyElement}
			<div className="footer">
				<UseButton
					clicked={submitClickHandler}
					value={buttonText}
					type="button"
					skin={buttonSkin}
					size="md"
					display="default"
					style={{ marginLeft: "auto" }}
				/>
			</div>
		</div>
	);
};

export default PricePopup;

PricePopup.propTypes = {
	selected: PropTypes.object,
	products: PropTypes.array,
	devices: PropTypes.array,
	type: typePropsValidator.isRequired,
	success: PropTypes.func.isRequired,
};
