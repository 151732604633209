import React from "react";
import PropTypes from "prop-types";
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
} from "recharts";

import { TooltipStyled } from "./useAreaChart";
import { getShortMonthName } from "./utility";

let COLORS = [
	"#D32F2F",
	"#1976D2",
	"#F57C00",
	"#C2185B",
	"#303F9F",
	"#5D4037",
	"#388E3C",
	"#7B1FA2",
	"#E64A19",
	"#455A64",
	"#009688",
	"#4CAF50",
	"#8BC24B",
	"#CCDB3A",
	"#FEEA3B",
	"#FEC00A",
	"#FE9800",
	"#FE5622",
	"#795548",
	"#9E9E9E",
	"#E0E0E0",
	"#617D8B",
	"#F34236",
	"#E72062",
	"#9B28B0",
	"#673AB7",
	"#3F51B5",
	"#2196F2",
	"#06A9F3",
	"#02BCD3",
];

const CustomTooltipLine = ({ active, payload, label, totalText }) => {
	if (active && payload) {
		return (
			<TooltipStyled>
				<b className="title">{`${label}`}</b>
				{payload.length > 0 &&
					payload.map((item, index) => (
						<span key={index} style={{ color: item.color }}>
							{totalText}:<span>{item.value}</span>
						</span>
					))}
			</TooltipStyled>
		);
	}
	return null;
};

const UseLineChart = ({ width, height, data, selected, totalText }) => {
	let lineElement = null;
	let isMultiLine = selected.length > 1;
	if (isMultiLine) {
		lineElement = selected.map((line, index) => (
			<Line
				key={line.id}
				type="monotone"
				dataKey={line.name}
				stroke={COLORS[index]}
				activeDot={{ r: 8 }}
			/>
		));
	} else {
		lineElement = (
			<Line
				type="monotone"
				dataKey="y"
				stroke={COLORS[0]}
				activeDot={{ r: 8 }}
			/>
		);
	}
	return (
		<LineChart
			width={width}
			height={height}
			data={data}
			margin={{
				top: 40,
				right: 40,
				left: -20,
				bottom: 10,
			}}
		>
			<CartesianGrid strokeDasharray="1 2" vertical={false} />
			<XAxis dataKey="x" tickFormatter={getShortMonthName} />
			<YAxis />
			{isMultiLine ? (
				<>
					<Legend />
					<Tooltip />
				</>
			) : (
				<Tooltip totalText={totalText} content={<CustomTooltipLine />} />
			)}
			{lineElement}
		</LineChart>
	);
};

export default UseLineChart;

UseLineChart.propTypes = {
	width: PropTypes.number.isRequired,
	height: PropTypes.number.isRequired,
	data: PropTypes.array.isRequired,
	selected: PropTypes.array,
	totalText: PropTypes.string,
};
