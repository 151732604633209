import React from "react";
import { useLocation, Navigate } from "react-router-dom";

import { AuthContext } from "../components/authContextProvider";

const NotFound = () => {
  let { pathname } = useLocation();
  const { user } = React.useContext(AuthContext);
  return (
    <Navigate
      to={user ? "/clients" : "/"}
      state={{
        from: pathname,
      }}
    />
  );
};

export default NotFound;
