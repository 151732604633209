import React from "react";
import PropTypes from "prop-types";

import Footer from "./footer";
import Header from "./header";
import { OuterWrapper } from "../theme";

const PageLayout =
  (WrappedComponent, type = "") =>
  (props) => {
    return (
      <OuterWrapper>
        <Header />
        <div className="body">
          <div className={`page ${type}`}>
            <WrappedComponent {...props} />
          </div>
          <Footer />
        </div>
      </OuterWrapper>
    );
  };

export default PageLayout;

PageLayout.propTypes = {
  WrappedComponent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  type: PropTypes.oneOf(["profile", ""]),
};
