import React from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";

import useWindowSize from "./useWindowSize";
import useBlanket from "./useBlanket";
import { typePropsValidator } from "./utility";

const UseDialog = React.forwardRef(
	({ title, icon, size = "md", type = "add", children }, ref) => {
		const { width, height } = useWindowSize();
		const [isOpen, setIsOpen] = React.useState(false);

		const closeDialog = React.useCallback(() => setIsOpen(false), []);

		React.useImperativeHandle(ref, () => ({
			Open() {
				setIsOpen(true);
			},
			Close() {
				closeDialog();
			},
		}));

		useBlanket(isOpen, closeDialog);

		let headerClassName = "";
		if (size === "xs") headerClassName = "qrCode";
		if (!isOpen) return null;
		return (
			<UseDialogStyled height={height} width={width} size={size} type={type}>
				<div>
					<div className={`header ${headerClassName}`}>
						{icon}
						<h2>{title}</h2>
						{size !== "xs" && (
							<span className="close" onClick={closeDialog}></span>
						)}
					</div>
					{children}
				</div>
			</UseDialogStyled>
		);
	}
);

export default UseDialog;

UseDialog.propTypes = {
	title: PropTypes.string.isRequired,
	icon: PropTypes.element,
	size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
	type: typePropsValidator,
	children: PropTypes.node,
};

export const UseDialogStyled = Styled.div`
  ${(props) => {
		let theme = props.theme;

		let boxSize = "730px";
		let changeWidth = theme.mMX_SM;

		if (props.size === "sm") {
			boxSize = "480px";
			changeWidth = theme.mMX_XS;
		} else if (props.size === "lg") {
			boxSize = "950px";
			changeWidth = theme.mMX_MD;
		} else if (props.size === "xs") {
			boxSize = "306px";
			changeWidth = theme.mMX_XS;
		}

		let iconColor = theme.primary;
		let textColor = theme.secondary;
		if (props.type === "edit") {
			iconColor = theme.secondary;
		} else if (props.type === "delete") {
			iconColor = theme.red;
			textColor = theme.red;
		}

		return `
      z-index:19;
      width: 100%;
      display:flex;
      justify-content:center;
      align-items:center;

      position: absolute;
      top: 0;
      left: 0;
      height: ${props.height}px;
      width: ${props.width}px;
      pointer-events: none;

      >div{
        height:auto;
        pointer-events: auto;
        border-radius:4px;

        width:${boxSize};
        background:rgba(${theme.white},1);

        ${changeWidth}{
            width:90% !important;
        }

        .header{
          display:flex;
          position:relative;
          padding:${theme.gMD} ${theme.gLG};
          height:20px;
          border-bottom:1px solid rgba(${theme.black},0.0625);
          align-items: center;

          >svg{
            color:rgba(${iconColor},1);
            margin-right:${theme.gMD};
            width:20px;
            height:100%;
          }
          >h2{
            color:rgba(${textColor},1);
            ${theme.fMD};
            text-transform: uppercase;
            display:flex;
            align-items: center;

            ${theme.mMX_XS}{
              ${theme.fSM};
            }
          }

          .close {
            width: 20px;
            height: 20px;
            opacity: 0.3;
            cursor:pointer;
            margin-left:auto;
          }
          .close:hover {
            opacity: 1;
          }
          .close:before, .close:after {
            position: absolute;
            right:calc(20px + 3px); // based on padding of .header
            top:calc((41px - 20px) / 2); // based on padding of .header
            content: ' ';
            height: 20px;
            width: 2px;
            background-color: #333;
          }
          .close:before {
            transform: rotate(45deg);
          }
          .close:after {
            transform: rotate(-45deg);
          }
        }

        .qrCode{
          height:40px;
          align-items:center;
          justify-content:center;
          >h2{
            ${theme.fXL};
          }
        }

        .body{
          padding:${theme.gLG};
          max-height:${boxSize};
          overflow:auto;
        }

        .footer{
          border-top:1px solid rgba(${theme.black},0.0625);
          height:40px;
          padding:${theme.gLG};

          display: flex;
          flex-direction:column;
          justify-content:center;
        }
      }

    `;
	}}
`;
